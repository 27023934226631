import React from 'react';
import styled from 'styled-components';

import { Grid, Icon, Flex } from '@marvelapp/ui';
import { CopyToClipboard } from '@marvelapp/ui-internal';

import {
  ADD_TO_FOLDER_ICON,
  ARCHIVE_ICON,
  ARROW_DOWN_ICON,
  ARROW_LEFT_ICON,
  ARROW_RIGHT_ICON,
  ARROW_UP_ICON,
  COPY_ICON,
  DELETE_ICON,
  CHECKBOX_TICK_ICON,
  DASH_ICON,
  PENDING_ICON,
  SETTINGS_ICON,
  PLAY_ICON,
  CLOSE_ICON,
  IMAGE_ICON,
  TRIANGLE_UP_ICON,
  TRIANGLE_RIGHT_ICON,
  TRIANGLE_DOWN_ICON,
  TRIANGLE_LEFT_ICON,
  MINUS_ICON,
  PLUS_ICON,
  CARET_ICON,
  SEARCH_ICON,
  VOLUME_ICON_FULL,
  VOLUME_ICON_HALF,
  VOLUME_ICON_NONE,
  PAUSE_ICON,
  PENCIL_ICON,
  UPLOAD_ICON,
  INFO_ICON,
  INFO_ICON_CIRCLE,
  WARNING_ICON,
  SHARE_ICON,
  CREATE_SECTION,
  ADD_TO_SECTION,
  UNGROUP,
  WARNING_TRIANGLE_ICON,
  REDO_ICON,
  UNDO_ICON,
  VIDEO_ICON,
  SCREEN_ICON,
  REFRESH_ICON,
  DOWNLOAD_ICON,
  EXTERNAL_ARROW_LIGHT_ICON,
  EXTERNAL_ARROW_ICON,
  LOCK_ICON,
  COMMENT_ICON,
  QUESTION_ICON,
  CONCEALED_ICON,
  REVEALED_ICON,
  LINK_ICON,
  NO_EDIT_ICON,
  CARET_RIGHT_ICON,
  QR_ICON,
  ANCHOR_ICON,
  LAYER_ICON,
  LAYERS_ICON,
  SORT_ICON,
  COMMENT_REPLY_ICON,
  COMMENT_REACT_ICON,
  COMMENT_MORE_ICON,
  CAMERA_ICON,
  MICROPHONE_ICON,
  DOTS_ICON,
} from '@marvelapp/theme';

const icons = [
  {
    name: 'ADD_TO_FOLDER_ICON',
    paths: ADD_TO_FOLDER_ICON,
  },
  {
    name: 'ARCHIVE_ICON',
    paths: ARCHIVE_ICON,
  },
  {
    name: 'ARROW_DOWN_ICON',
    paths: ARROW_DOWN_ICON,
  },
  {
    name: 'ARROW_LEFT_ICON',
    paths: ARROW_LEFT_ICON,
  },
  {
    name: 'ARROW_RIGHT_ICON',
    paths: ARROW_RIGHT_ICON,
  },
  {
    name: 'ARROW_UP_ICON',
    paths: ARROW_UP_ICON,
  },
  {
    name: 'COPY_ICON',
    paths: COPY_ICON,
  },
  {
    name: 'DELETE_ICON',
    paths: DELETE_ICON,
  },
  {
    name: 'CHECKBOX_TICK_ICON',
    paths: CHECKBOX_TICK_ICON,
  },
  {
    name: 'DASH_ICON',
    paths: DASH_ICON,
  },
  {
    name: 'PENDING_ICON',
    paths: PENDING_ICON,
  },
  {
    name: 'SETTINGS_ICON',
    paths: SETTINGS_ICON,
  },
  {
    name: 'PLAY_ICON',
    paths: PLAY_ICON,
  },
  {
    name: 'CLOSE_ICON',
    paths: CLOSE_ICON,
  },
  {
    name: 'IMAGE_ICON',
    paths: IMAGE_ICON,
  },
  {
    name: 'TRIANGLE_UP_ICON',
    paths: TRIANGLE_UP_ICON,
  },
  {
    name: 'TRIANGLE_RIGHT_ICON',
    paths: TRIANGLE_RIGHT_ICON,
  },
  {
    name: 'TRIANGLE_DOWN_ICON',
    paths: TRIANGLE_DOWN_ICON,
  },
  {
    name: 'TRIANGLE_LEFT_ICON',
    paths: TRIANGLE_LEFT_ICON,
  },
  {
    name: 'MINUS_ICON',
    paths: MINUS_ICON,
  },
  {
    name: 'PLUS_ICON',
    paths: PLUS_ICON,
  },
  {
    name: 'CARET_ICON',
    paths: CARET_ICON,
  },
  {
    name: 'SEARCH_ICON',
    paths: SEARCH_ICON,
  },
  {
    name: 'VOLUME_ICON_FULL',
    paths: VOLUME_ICON_FULL,
  },
  {
    name: 'VOLUME_ICON_HALF',
    paths: VOLUME_ICON_HALF,
  },
  {
    name: 'VOLUME_ICON_NONE',
    paths: VOLUME_ICON_NONE,
  },
  {
    name: 'PAUSE_ICON',
    paths: PAUSE_ICON,
  },
  {
    name: 'PENCIL_ICON',
    paths: PENCIL_ICON,
  },
  {
    name: 'UPLOAD_ICON',
    paths: UPLOAD_ICON,
  },
  {
    name: 'INFO_ICON',
    paths: INFO_ICON,
  },
  {
    name: 'INFO_ICON',
    paths: LAYERS_ICON,
  },
  {
    name: 'INFO_ICON_CIRCLE',
    paths: INFO_ICON_CIRCLE,
  },
  {
    name: 'WARNING_ICON',
    paths: WARNING_ICON,
  },
  {
    name: 'SHARE_ICON',
    paths: SHARE_ICON,
  },
  {
    name: 'CREATE_SECTION',
    paths: CREATE_SECTION,
  },
  {
    name: 'ADD_TO_SECTION',
    paths: ADD_TO_SECTION,
  },
  {
    name: 'UNGROUP',
    paths: UNGROUP,
  },
  {
    name: 'WARNING_TRIANGLE_ICON',
    paths: WARNING_TRIANGLE_ICON,
  },
  {
    name: 'REDO_ICON',
    paths: REDO_ICON,
  },
  {
    name: 'UNDO_ICON',
    paths: UNDO_ICON,
  },
  {
    name: 'VIDEO_ICON',
    paths: VIDEO_ICON,
  },
  {
    name: 'SCREEN_ICON',
    paths: SCREEN_ICON,
  },
  {
    name: 'REFRESH_ICON',
    paths: REFRESH_ICON,
  },
  {
    name: 'DOWNLOAD_ICON',
    paths: DOWNLOAD_ICON,
  },
  {
    name: 'EXTERNAL_ARROW_LIGHT_ICON',
    paths: EXTERNAL_ARROW_LIGHT_ICON,
  },
  {
    name: 'EXTERNAL_ARROW_ICON',
    paths: EXTERNAL_ARROW_ICON,
  },
  {
    name: 'LOCK_ICON',
    paths: LOCK_ICON,
  },
  {
    name: 'COMMENT_ICON',
    paths: COMMENT_ICON,
  },

  {
    name: 'QUESTION_ICON',
    paths: QUESTION_ICON,
  },
  {
    name: 'CONCEALED_ICON',
    paths: CONCEALED_ICON,
  },
  {
    name: 'REVEALED_ICON',
    paths: REVEALED_ICON,
  },
  {
    name: 'LINK_ICON',
    paths: LINK_ICON,
  },
  {
    name: 'NO_EDIT_ICON',
    paths: NO_EDIT_ICON,
  },
  {
    name: 'CARET_RIGHT_ICON',
    paths: CARET_RIGHT_ICON,
  },
  {
    name: 'QR_ICON',
    paths: QR_ICON,
  },
  {
    name: 'ANCHOR_ICON',
    paths: ANCHOR_ICON,
  },
  {
    name: 'LAYER_ICON',
    paths: LAYER_ICON,
  },
  {
    name: 'SORT_ICON',
    paths: SORT_ICON,
  },
  {
    name: 'COMMENT_REPLY_ICON',
    paths: COMMENT_REPLY_ICON,
  },
  {
    name: 'COMMENT_REACT_ICON',
    paths: COMMENT_REACT_ICON,
  },
  {
    name: 'COMMENT_MORE_ICON',
    paths: COMMENT_MORE_ICON,
  },
  {
    name: 'CAMERA_ICON',
    paths: CAMERA_ICON,
  },
  {
    name: 'MICROPHONE_ICON',
    paths: MICROPHONE_ICON,
  },
  {
    name: 'DOTS_ICON',
    paths: DOTS_ICON,
  },
];

const Cell = styled(Flex)`
  &:nth-child(5n) {
    border-right: none;
  }

  &:nth-child(5n + 1):nth-last-child(-n + 5),
  &:nth-child(5n + 1):nth-last-child(-n + 5) ~ & {
    border-bottom: none;
  }
`;

export default function IconsGrid() {
  return (
    <Grid
      border="1px solid"
      borderColor="smoke"
      borderRadius={2}
      gridTemplateColumns="20% 20% 20% 20% 20%"
      overflow="hidden"
    >
      {icons
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((icon, index) => (
          <Cell
            borderBottom="1px solid"
            borderRight="1px solid"
            // eslint-disable-next-line
            borderColor="smoke"
            cursor="pointer"
            flexDirection="column"
            height={150}
            hoverBg="snow"
            // eslint-disable-next-line
            key={index}
            padding={4}
            position="relative"
            transition="smooth.fast"
          >
            <Flex alignItems="center" flex={1} justifyContent="center">
              <Icon paths={icon.paths} />
            </Flex>
            <Flex alignItems="center" height={20} justifyContent="center">
              <CopyToClipboard
                alignItems="flex-end"
                as={Flex}
                bottom={0}
                btnText={icon.name}
                display="flex"
                fontSize={0}
                justifyContent="center"
                left={0}
                padding={4}
                position="absolute"
                right={0}
                text={icon.name}
                textAlign="center"
                top={0}
              />
            </Flex>
          </Cell>
        ))}
    </Grid>
  );
}
