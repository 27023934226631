import { Box, Link, NotificationBar } from '@marvelapp/ui';
import { SETTINGS_ICON } from '@marvelapp/theme';
import Example from "../../../../src/components/Example";
import PropsTable from "../../../../src/components/PropsTable";
import * as React from 'react';
export default {
  Box,
  Link,
  NotificationBar,
  SETTINGS_ICON,
  Example,
  PropsTable,
  React
};