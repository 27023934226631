import { Box, Flex, Tooltip } from '@marvelapp/ui';
import Example from "../../../../src/components/Example";
import PropsTable from "../../../../src/components/PropsTable";
import * as React from 'react';
export default {
  Box,
  Flex,
  Tooltip,
  Example,
  PropsTable,
  React
};