export const getSystemPropTypes = (propTypes, defaults) => {
  const keys = Object.keys(propTypes);
  const array = [];
  keys.map((key) =>
    array.push({
      name: key,
      type: ['array', 'string', 'number'],
      default: defaults && defaults[key],
    })
  );
  return array;
};
