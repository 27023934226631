import React, { useEffect, useState } from 'react';
import { loadImage } from '../utils';
import { ImageElement } from './styles';
/** @param {any} */
function Image({ alt, disabled, finishedLoading, hasTransition = true, preloadImage = true, src, src2x, testId, ...rest }) {
    const [isCancelled, setIsCancelled] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const fetchImage = () => {
        if (!src || !hasTransition) {
            return;
        }
        loadImage(src)
            .then(() => {
            if (!isCancelled) {
                setImageLoaded(true);
                if (finishedLoading) {
                    finishedLoading({ success: true });
                }
            }
        })
            .catch(() => {
            finishedLoading({ success: false });
        });
    };
    const cleanup = () => {
        setIsCancelled(true);
    };
    useEffect(() => {
        fetchImage();
        return cleanup;
    });
    const transitionProps = getOpacityProps({
        imageLoaded,
        hasTransition,
        disabled,
    });
    const disabledProps = disabled && {
        filter: 'grayscale(1)',
    };
    return (React.createElement(ImageElement, Object.assign({ alt: alt, "data-testid": testId, draggable: false, preloadImage: preloadImage, src: src, srcSet: src2x && `${src2x} 2x` }, disabledProps, transitionProps, rest)));
}
function getOpacityProps({ imageLoaded, hasTransition, disabled }) {
    if (disabled) {
        return { opacity: 0.5 };
    }
    if (hasTransition && !disabled) {
        return { opacity: imageLoaded ? 1 : 0 };
    }
    if (!hasTransition && !disabled) {
        return { opacity: 1 };
    }
}
export default Image;
