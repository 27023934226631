import { Image } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import image from "../../../../content/docs/components/assets/image.png";
import imageRetina from "../../../../content/docs/components/assets/image@2x.png";
import * as React from 'react';
export default {
  Image,
  PropsTable,
  image,
  imageRetina,
  React
};