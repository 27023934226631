import styled, { keyframes, css } from 'styled-components';
import { opacity, layout, border, space, flexbox } from 'styled-system';
import { colors } from '@marvelapp/theme';
const BACKGROUND_SIZE = 468;
const placeHolderShimmer = keyframes `
  0%{
    background-position: -${BACKGROUND_SIZE}px 0
  }
  100%{
    background-position: ${BACKGROUND_SIZE}px 0
  }
`;
export const ContentLoader = styled.div `
  ${border};
  ${layout};
  ${opacity};
  ${space};
  ${flexbox};
  animation-duration: 1600ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: ease-in-out;

  ${(props) => props.kind === 'light' &&
    css `
      background: ${colors.snow};
      background: linear-gradient(
        to right,
        ${colors.snow} 8%,
        ${colors.snowDark} 18%,
        ${colors.snow} 33%
      );
    `};

  ${(props) => props.kind === 'medium' &&
    css `
      background: ${colors.snowDark};
      background: linear-gradient(
        to right,
        ${colors.snowDark} 8%,
        ${colors.snowExtraDark} 18%,
        ${colors.snowDark} 33%
      );
    `};

  ${(props) => props.kind === 'dark' &&
    css `
      background: ${colors.slate};
      background: linear-gradient(
        to right,
        ${colors.slate} 8%,
        #4a5664 18%,
        ${colors.slate} 33%
      );
    `};

  background-size: 800px 104px;
`;
ContentLoader.defaultProps = {
    kind: 'light',
};
