import React from 'react';
import { CLOSE_ICON } from '@marvelapp/theme';
import Box from '../Box';
import Text from '../Text';
import Icon from '../Icon';
import Flex from '../Flex';
import { sizes } from './sizes';
function NotificationBar({ bg, children, color = 'white', icon, onClickClose, size = 0, hasMenuButtonOnPage, ...rest }) {
    return (React.createElement(Flex, Object.assign({ alignItems: "center", bg: bg, justifyContent: "center", minHeight: sizes[size].minHeight, px: sizes[size].px, py: sizes[size].py, width: "100%" }, rest),
        React.createElement(Flex, { display: "flex", flex: 1, justifyContent: "center", pl: hasMenuButtonOnPage && [75, 75, 0, 0], pr: hasMenuButtonOnPage && [75, 75, 0, 0] },
            icon && (React.createElement(Box, null,
                React.createElement(Icon, { color: color, height: 18, mr: 2, paths: icon, width: 18 }))),
            React.createElement(Box, null,
                React.createElement(Text, { as: "span", color: color, fontSize: sizes[size].fontSize }, children))),
        onClickClose && (React.createElement(Box, { cursor: "pointer", onClick: onClickClose },
            React.createElement(Icon, { color: color, display: "block", hoverColor: "snowDark", mr: 2, paths: CLOSE_ICON })))));
}
NotificationBar.defaultProps = {
    bg: 'marvel',
};
export default NotificationBar;
