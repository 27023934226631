export const sizes = [
    {
        fontSize: 2,
        px: 3,
        py: 2,
        minHeight: 40,
    },
    {
        fontSize: 3,
        px: 3,
        py: 2,
        minHeight: 60,
    },
];
