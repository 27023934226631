import React from 'react';
import { PLUS_ICON, MINUS_ICON } from '@marvelapp/theme';
import Box from './Box';
import Button from './Button';
import Flex from './Flex';
import Icon from './Icon';
import Tooltip from './Tooltip';
import { buttonSizes as sizes } from './Button/sizes';
import kinds from './Button/kinds';
export default function Stepper({ children, decrementDisabledReason, incrementDisabledReason, isDecrementDisabled, isIncrementDisabled, kind = 'ghost', onDecrement, onIncrement, size = 1, stepperTestId, tooltipWhiteSpace, tooltipWidth, width = '100%', }) {
    const buttonProps = {
        px: 2,
        display: 'flex',
        alignItems: 'center',
        translateOnHover: false,
        kind,
        size,
    };
    return (React.createElement(Flex, { "data-testid": stepperTestId, width: width },
        React.createElement(StepperButton, { disabledReason: isDecrementDisabled && decrementDisabledReason, tooltipWhiteSpace: tooltipWhiteSpace, tooltipWidth: tooltipWidth },
            React.createElement(Button, Object.assign({ "data-testid": "decrease-button", isDisabled: isDecrementDisabled, onClick: onDecrement }, buttonProps, { borderRadius: "99px 0 0 99px" }),
                React.createElement(Icon, { paths: MINUS_ICON }))),
        React.createElement(Box, Object.assign({}, sizes()[size], kinds[kind], { borderRadius: "0px", flex: 1, fontWeight: 500, hoverBg: undefined, hoverColor: undefined, marginLeft: "-1px", marginRight: "-1px", textAlign: "center" }),
            React.createElement("div", { "data-testid": "member-info" }, children)),
        React.createElement(StepperButton, { disabledReason: isIncrementDisabled && incrementDisabledReason, tooltipWhiteSpace: tooltipWhiteSpace, tooltipWidth: tooltipWidth },
            React.createElement(Button, Object.assign({ "data-testid": "increase-button", isDisabled: isIncrementDisabled, onClick: onIncrement }, buttonProps, { borderRadius: "0 99px 99px 0" }),
                React.createElement(Icon, { paths: PLUS_ICON })))));
}
function StepperButton({ children, disabledReason, tooltipWhiteSpace, tooltipWidth, }) {
    if (disabledReason) {
        return (React.createElement(Tooltip, { content: disabledReason, cursor: "default", whiteSpace: tooltipWhiteSpace, width: tooltipWidth }, children));
    }
    return children;
}
