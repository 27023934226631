import React from 'react';
import { Flex } from '@marvelapp/ui';
export function ListRowCell({ children, isInteractive, testId, display }) {
    const handleCellClick = (e) => {
        if (isInteractive) {
            e.stopPropagation();
        }
    };
    return (React.createElement(Flex, { alignItems: "center", "data-testid": testId, display: display, flex: 1, minWidth: 0, onClick: handleCellClick }, children));
}
