/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ContentLoader } from '@marvelapp/ui';
import { range } from 'lodash-es';
import { ListRow } from '../ListRow';
import { ListRowCell } from '../ListRowCell';
export function ListRowLoader({ types, rowCount = 3 }) {
    const rows = range(rowCount).map((_, rowIdx) => (React.createElement(ListRow, { disabled: true, key: rowIdx }, types.map((type, typeIdx) => (React.createElement(ListRowCell, { key: typeIdx },
        React.createElement(CellLoader, { type: type })))))));
    return rows;
}
export const LoaderCellType = {
    Text: 'text',
    Avatar: 'avatar',
    AvatarWithText: 'avatar-with-text',
    AvatarGroup: 'avatar-group',
};
function CellLoader({ type }) {
    switch (type) {
        case LoaderCellType.Avatar:
            return React.createElement(AvatarLoader, null);
        case LoaderCellType.AvatarWithText:
            return React.createElement(AvatarWithTextLoader, null);
        case LoaderCellType.Text:
            return React.createElement(TextLoader, null);
        case LoaderCellType.AvatarGroup:
            return React.createElement(AvatarGroupLoader, null);
        default:
            return null;
    }
}
const TextLoader = (props) => (React.createElement(ContentLoader, Object.assign({ borderRadius: 4, flex: "1", height: "12px" }, props)));
const AvatarLoader = (props) => (React.createElement(ContentLoader, Object.assign({ borderRadius: "50%", height: "32px", width: "32px" }, props)));
const AvatarGroupLoader = () => {
    const AvatarPlaceholder = ({ style }) => (React.createElement(AvatarLoader, { border: "2px solid white", style: style }));
    return (React.createElement(React.Fragment, null,
        React.createElement(AvatarPlaceholder, null),
        React.createElement(AvatarPlaceholder, { style: { transform: 'translateX(-6px)' } }),
        React.createElement(AvatarPlaceholder, { style: { transform: 'translateX(-12px)' } })));
};
const AvatarWithTextLoader = () => (React.createElement(React.Fragment, null,
    React.createElement(AvatarLoader, { mr: 3 }),
    React.createElement(TextLoader, null)));
