import React from 'react';
import { CHECKBOX_TICK_ICON } from '@marvelapp/theme';
import Icon from '../Icon';
import Flex from '../Flex';
import Text from '../Text';
import Box from '../Box';
import sizes from './sizes';
export default function LiNumbered({ size, children, ...rest }) {
    return (React.createElement(Box, Object.assign({ as: "li" }, rest),
        React.createElement(Flex, { alignItems: "center", mb: sizes[size].spacing },
            React.createElement(Icon, { color: "marvel", display: "block", height: sizes[size].iconSize, mr: sizes[size].iconMarginRight, paths: CHECKBOX_TICK_ICON, width: sizes[size].iconSize }),
            React.createElement(Text, { fontSize: sizes[size].fontSize }, children))));
}
LiNumbered.defaultProps = {
    size: 2,
};
