import { Box, List, Li, LiCheck, LiNumbered } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import * as React from 'react';
export default {
  Box,
  List,
  Li,
  LiCheck,
  LiNumbered,
  PropsTable,
  Example,
  React
};