import { Box, Button, Image, SlimUpseller } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import tourImage from "../../../../content/docs/components/assets/tour.png";
import tourImage2x from "../../../../content/docs/components/assets/tour@2x.png";
import * as React from 'react';
export default {
  Box,
  Button,
  Image,
  SlimUpseller,
  PropsTable,
  Example,
  tourImage,
  tourImage2x,
  React
};