import { colors } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
const kinds = {
    default: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'silver',
        color: 'silver',
    },
    ghost: {
        border: '1px solid',
        borderColor: 'smoke',
        color: 'slate',
    },
    ghostReversed: {
        bg: 'transparent',
        boxShadow: 'button.ghostReversed',
        color: 'white',
        fill: 'white',
    },
    marvel: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'marvel',
        color: 'marvelDark',
    },
    purple: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'purpleLight',
        color: 'purple',
    },
    marvelFilled: {
        bg: 'marvel',
        border: '1px solid',
        borderColor: 'marvel',
        color: 'white',
    },
    snowDark: {
        bg: 'snowDark',
        border: '1px solid',
        borderColor: 'snowDark',
        color: 'slate',
    },
    orange: {
        bg: hexToRGB(colors.orangeExtraExtraLight, 0.8),
        border: '1px solid',
        borderColor: 'orange',
        color: 'orange',
    },
    green: {
        bg: hexToRGB(colors.greenExtraLight, 0.8),
        border: '1px solid',
        borderColor: 'greenDark',
        color: 'greenDark',
    },
};
export default kinds;
