import { Button, Box, Grid, Logo, Flex, Image } from '@marvelapp/ui';
import book from "../../../../content/assets/book.jpg";
import bookRetina from "../../../../content/assets/book@2x.jpg";
import * as React from 'react';
export default {
  Button,
  Box,
  Grid,
  Logo,
  Flex,
  Image,
  book,
  bookRetina,
  React
};