import React, { useState } from 'react';
import { Box, Avatar, Button } from '@marvelapp/ui';
import {
  SelectableListView,
  ListRowActions,
  SelectableListRow,
  ListText,
  ListRowCell,
  SelectableListBoundary,
} from '@marvelapp/ui-internal';
import { projectsSetA } from './data';

export function SelectableListExample() {
  const [selectionMap, setSelectionMap] = useState({});

  const onSelectionChange = (val) => setSelectionMap(val);

  const renderSelectableItem = (item) => (
    <SelectableListRow rowKey={item.pk}>
      <ListRowCell>
        <Avatar mr={3} size={36} username={item.owner.username} />
        <ListText kind="bold">{item.title}</ListText>
      </ListRowCell>
      <ListRowActions>
        <Button kind="ghost">Some Action</Button>
        <Button kind="danger">Delete</Button>
      </ListRowActions>
    </SelectableListRow>
  );

  return (
    <Box borderRadius={2} boxShadow="popover.default" marginTop={4}>
      <SelectableListBoundary boundaryName="Example Boundary">
        <Box padding={6}>
          <SelectableListView
            onSelectionChange={onSelectionChange}
            selectionMap={selectionMap}
          >
            {projectsSetA.map(renderSelectableItem)}
          </SelectableListView>
        </Box>
      </SelectableListBoundary>
    </Box>
  );
}
