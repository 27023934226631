import React, { useRef, useCallback } from 'react';
import { Flex } from '@marvelapp/ui';
import { isNil } from 'lodash-es';
import { useDragBoundary } from './hooks/useDragBoundary';
import { useListContext, ListContext } from './hooks/useListContext';
import { ScrollToBottomTrigger } from './ScrollToBottomTrigger';
import { SelectableListHead, ListHead } from './ListHead';
import { ListRowLoader } from './ListRowLoader';
export function ListView({ children, columns = [], isLoadingRows = false, onScrollToBottom, listStyle = {}, }) {
    const listContextValue = {
        columns,
        isLoadingRows,
    };
    return (React.createElement(ListContext.Provider, { value: listContextValue },
        React.createElement(ListViewWrapper, { listStyle: listStyle, onScrollToBottom: onScrollToBottom }, children)));
}
export function SelectableListView({ children, columns = [], isLoadingRows = false, disabled = false, selectionMap = {}, onSelectionChange = () => null, selectionAlwaysVisible = false, onScrollToBottom, listStyle = {}, }) {
    const subscribedRows = useRef([]);
    const dragBoundaryExists = useDragBoundary(selectionMap, onSelectionChange) && !disabled;
    const isSelectionActive = !!Object.keys(selectionMap).find((key) => !!selectionMap[key]);
    const getIsRowSelected = (rowKey) => !!selectionMap[rowKey];
    const subscribeRow = (rowKey) => {
        subscribedRows.current.push(rowKey);
        // Return handler to remove listener
        return () => {
            subscribedRows.current = subscribedRows.current.filter((key) => key !== rowKey);
        };
    };
    const onSelect = (rowKey, val) => onSelectionChange({ ...selectionMap, [rowKey]: val });
    const onSelectAll = (isSelected) => {
        const updatedSelection = {};
        subscribedRows.current.forEach((key) => {
            updatedSelection[key] = isSelected;
        });
        onSelectionChange(updatedSelection);
    };
    const isAllSelected = useCallback(() => {
        const isAllSelectedInMap = Object.keys(selectionMap).every((k) => selectionMap[k]);
        return subscribedRows.current.length
            ? !subscribedRows.current.find((key) => !selectionMap[key])
            : isAllSelectedInMap;
    }, [selectionMap]);
    const isNoneSelected = useCallback(() => {
        const isNoneSelectedInMap = Object.keys(selectionMap).every((k) => !selectionMap[k]);
        return subscribedRows.current.length
            ? !subscribedRows.current.find((key) => selectionMap[key])
            : isNoneSelectedInMap;
    }, [selectionMap]);
    const listContextValue = {
        dragBoundaryExists,
        columns,
        isLoadingRows,
        onSelect,
        onSelectAll,
        isSelectionActive,
        subscribeRow,
        getIsRowSelected,
        isAllSelected,
        isNoneSelected,
        selectionAlwaysVisible,
        selectionDisabled: disabled,
    };
    return (React.createElement(ListContext.Provider, { value: listContextValue },
        React.createElement(ListViewWrapper, { isSelectable: true, listStyle: listStyle, onScrollToBottom: onScrollToBottom }, children)));
}
function ListViewWrapper({ children, listStyle, onScrollToBottom, isSelectable, }) {
    const listView = useListContext();
    const headings = listView.columns.map((c) => c.heading);
    const loaderTypes = listView.columns.map((c) => c.loader);
    const ListHeadComp = isSelectable ? SelectableListHead : ListHead;
    // We check for isNil to allow empty strings (or 0s) as list headings
    const hasHeadings = !!headings.filter((h) => !isNil(h)).length;
    return (React.createElement(Flex, Object.assign({ flexDirection: "column" }, listStyle),
        hasHeadings && React.createElement(ListHeadComp, { headings: headings }),
        children,
        listView.isLoadingRows && (React.createElement(ListRowLoader, { rowCount: 10, types: loaderTypes })),
        !listView.isLoadingRows ? (React.createElement(ScrollToBottomTrigger, { onTrigger: onScrollToBottom })) : null));
}
