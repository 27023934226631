import React, { forwardRef } from 'react';
import { Textarea } from '@marvelapp/ui';
import { sizes } from './sizes';
import Label from './Label';
/** @type {any} */
const TextareaWithLabel = forwardRef(({ id, isOptional, label, size = 3, fontSize, ...props }, ref) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { fontSize: fontSize, id: id, isOptional: isOptional, size: size }, label),
        React.createElement(Textarea, Object.assign({ id: id, ref: ref, size: sizes[size].textareaSize, width: 1 }, props))));
});
export default TextareaWithLabel;
