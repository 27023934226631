import { colors } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
const CARET_SIZE = 4;
export const positions = {
    top: {
        tooltip: {
            top: 'auto',
            bottom: '100%',
            margin: '0 0 10px 0',
        },
        arrow: {
            left: 'calc(50% + 1px)',
            bottom: `-${CARET_SIZE}px`,
            borderTop: `solid ${hexToRGB(colors.licorice, 0.9)} ${CARET_SIZE}px`,
            borderLeft: `solid transparent ${CARET_SIZE}px`,
            borderRight: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            height: '10px',
            bottom: '-10px',
            left: 0,
        },
    },
    left: {
        tooltip: {
            left: 'auto',
            right: '100%',
            bottom: 'auto',
            top: '50%',
            margin: '0 10px 0 0',
            transform: 'translateY(-50%)',
        },
        arrow: {
            top: '50%',
            bottom: 'auto',
            left: 'auto',
            right: `-${CARET_SIZE}px`,
            transform: 'translateY(-50%)',
            borderRight: 'none',
            borderLeft: `solid ${hexToRGB(colors.licorice, 0.9)} ${CARET_SIZE}px`,
            borderTop: `solid transparent ${CARET_SIZE}px`,
            borderBottom: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            width: '10px',
            left: '-10px',
            top: 0,
        },
    },
    right: {
        tooltip: {
            right: 'auto',
            left: '100%',
            bottom: 'auto',
            top: '50%',
            margin: '0 0 0 10px',
            transform: 'translateY(-50%)',
        },
        arrow: {
            top: '50%',
            bottom: 'auto',
            right: 'auto',
            left: 1,
            transform: 'translateY(-50%)',
            borderLeft: 'none',
            borderRight: `solid ${hexToRGB(colors.licorice, 0.9)} ${CARET_SIZE}px`,
            borderTop: `solid transparent ${CARET_SIZE}px`,
            borderBottom: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            width: '10px',
            left: 'auto',
            right: '-10px',
            top: 0,
        },
    },
    topLeft: {
        tooltip: {
            top: 'auto',
            bottom: '100%',
            left: 0,
            right: 'auto',
            margin: '0 0 10px 0',
            transform: 'none',
        },
        arrow: {
            right: 'auto',
            left: '12px',
            bottom: `-${CARET_SIZE}px`,
            borderBottom: 'none',
            borderTop: `solid ${hexToRGB(colors.black, 0.9)} ${CARET_SIZE}px`,
            borderLeft: `solid transparent ${CARET_SIZE}px`,
            borderRight: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            height: '10px',
            bottom: '-10px',
            top: 'auto',
            left: 0,
        },
    },
    topRight: {
        tooltip: {
            top: 'auto',
            bottom: '100%',
            right: 0,
            left: 'auto',
            margin: '0 0 10px 0',
            transform: 'none',
        },
        arrow: {
            left: 'auto',
            right: '12px',
            bottom: `-${CARET_SIZE}px`,
            borderBottom: 'none',
            borderTop: `solid ${hexToRGB(colors.black, 0.9)} ${CARET_SIZE}px`,
            borderLeft: `solid transparent ${CARET_SIZE}px`,
            borderRight: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            height: '10px',
            bottom: '-10px',
            top: 'auto',
            left: 0,
        },
    },
    bottom: {
        tooltip: {
            bottom: 'auto',
            top: '100%',
            margin: '10px 0 0 0',
        },
        arrow: {
            top: `-${CARET_SIZE}px`,
            borderTop: 'none',
            borderBottom: `solid ${hexToRGB(colors.black, 0.9)} ${CARET_SIZE}px`,
            borderLeft: `solid transparent ${CARET_SIZE}px`,
            borderRight: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            height: '10px',
            top: '-10px',
            bottom: 'auto',
            left: 0,
        },
    },
    bottomLeft: {
        tooltip: {
            bottom: 'auto',
            top: '100%',
            right: 'auto',
            left: 0,
            margin: '10px 0 0 0',
            transform: 'none',
        },
        arrow: {
            right: 'auto',
            left: '12px',
            top: `-${CARET_SIZE}px`,
            borderTop: 'none',
            borderBottom: `solid ${hexToRGB(colors.black, 0.9)} ${CARET_SIZE}px`,
            borderLeft: `solid transparent ${CARET_SIZE}px`,
            borderRight: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            height: '10px',
            top: '-10px',
            bottom: 'auto',
            left: 0,
        },
    },
    bottomRight: {
        tooltip: {
            bottom: 'auto',
            top: '100%',
            left: 'auto',
            right: 0,
            margin: '10px 0 0 0',
            transform: 'none',
        },
        arrow: {
            left: 'auto',
            right: '12px',
            top: `-${CARET_SIZE}px`,
            borderTop: 'none',
            borderBottom: `solid ${hexToRGB(colors.black, 0.9)} ${CARET_SIZE}px`,
            borderLeft: `solid transparent ${CARET_SIZE}px`,
            borderRight: `solid transparent ${CARET_SIZE}px`,
        },
        gap: {
            height: '10px',
            top: '-10px',
            bottom: 'auto',
            left: 0,
        },
    },
};
