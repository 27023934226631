import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Row } from '@marvelapp/ui';
import { createSelectable } from '@marvelapp/react-selectable';
import { ListCheckbox, ListCheckboxGutter } from '../ListCheckbox';
import { ListText, MainListText } from '../ListText';
import { useListContext } from '../hooks/useListContext';
export function ListRow({ children, onClick, disabled, testId }) {
    const listView = useListContext();
    const layout = listView.getRowLayout(children);
    return (React.createElement(RowContentWrapper, { "data-testid": testId, disabled: disabled, layout: layout, onClick: disabled ? null : onClick }, children));
}
const ReactSelectableWrapper = createSelectable(({ children, selectableRef }) => (React.createElement(SelectableListRowWrapper, { ref: selectableRef }, children)));
export function SelectableListRow({ children, rowKey, onClick, disabled, testId, }) {
    const listView = useListContext();
    const layout = listView.getRowLayout(children);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => listView.subscribeRow(rowKey), []);
    const onChange = (e) => listView.onSelect(rowKey, e.target.checked);
    const isSelected = listView.getIsRowSelected(rowKey);
    const checkbox = !listView.selectionDisabled ? (React.createElement(ListCheckbox, { isChecked: isSelected, onChange: onChange, testId: `${testId}-checkbox` })) : null;
    return (React.createElement(ReactSelectableWrapper, { isSelectable: listView.dragBoundaryExists, selectableKey: rowKey },
        React.createElement(ListCheckboxGutter, { isIndented: listView.selectionAlwaysVisible, isVisible: isSelected ||
                listView.isSelectionActive ||
                listView.selectionAlwaysVisible }, checkbox),
        React.createElement(RowContentWrapper, { "data-testid": testId, disabled: disabled, isSelected: isSelected, layout: layout, onClick: disabled ? null : onClick, pl: listView.selectionAlwaysVisible ? 52 : 3 }, children)));
}
const SelectableListRowWrapper = styled(Flex) `
  position: relative;
  align-items: center;

  &:hover {
    .list-checkbox-gutter {
      opacity: 1;
    }
  }
`;
const RowContentWrapper = styled(Row) `
  align-items: center;
  display: grid;
  flex: 1;
  grid-template-columns: ${(p) => p.layout.gridTemplateColumns};
  grid-gap: ${(p) => p.layout.gridGap};

  &:hover {
    .list-row-actions {
      opacity: 1;
    }
  }

  ${(p) => p.onClick &&
    !p.disabled &&
    `
      &:hover {
        cursor: pointer;

        ${MainListText} {
          color: ${p.theme.colors.marvelDark};
        }
      };
  `}

  ${(p) => p.onClick &&
    !p.disabled &&
    `
      &:hover {
        cursor: pointer;

        ${MainListText} {
          color: ${p.theme.colors.marvelDark};
        }

        ${ListText} {
          color: ${p.theme.colors.marvelDark};
        }
      };
  `}

  ${(p) => p.disabled &&
    `
      ${ListText} {
        color: ${p.theme.colors.silver};
      }
  `}

  ${(p) => !p.onClick &&
    `
    &:hover {
      background: none;
      cursor: default;
    }
  `}
`;
