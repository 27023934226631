export const collaborators = {
    edges: [
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/KF.svg',
                pk: 352174,
                username: 'kas.fijolek@marvelapp.com',
            },
        },
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/YP.svg',
                pk: 14913,
                username: 'Yavor Punchev',
            },
        },
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/JS.svg',
                pk: 1138925,
                username: 'jonathan.siao@marvelapp.com',
            },
        },
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/MA.svg',
                pk: 352174,
                username: 'mark.allison@marvelapp.com',
            },
        },
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/DB.svg',
                pk: 352174,
                username: 'dean.blacc@marvelapp.com',
            },
        },
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/RF.svg',
                pk: 352174,
                username: 'rachel.franklin@marvelapp.com',
            },
        },
        {
            node: {
                avatarUrl: 'https://marvelapp.com/avatar/BA.svg',
                pk: 352174,
                username: 'boris.ablamuntis@marvelapp.com',
            },
        },
    ],
};
