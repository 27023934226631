import React from 'react';
import PropTypes from 'prop-types';
import { CLOSE_ICON } from '@marvelapp/theme';
import Box from '../Box';
import Text from '../Text';
import Icon from '../Icon';
import PinTR from '../PinTR';
import { ImageHolder, TinyUpsellerWrapper } from './styles';
function TinyUpseller({ image, onClickClose, title, callToAction, ...rest }) {
    return (React.createElement(TinyUpsellerWrapper, Object.assign({ alignItems: "center", borderRadius: 2, boxShadow: "button.ghost", flexDirection: "column", justifyContent: "center", position: "relative", px: 3, py: 4, width: 156 }, rest),
        React.createElement(ImageHolder, null, image),
        React.createElement(Text, { color: "licorice", fontSize: 2, fontWeight: 500, pb: 3, pt: 3, textAlign: "center" }, title),
        callToAction,
        onClickClose && (React.createElement(PinTR, { top: 6 },
            React.createElement(Box, { cursor: "pointer", "data-testid": "upsell-close", onClick: onClickClose },
                React.createElement(Icon, { color: "silver", display: "block", hoverColor: "black", mr: 2, paths: CLOSE_ICON }))))));
}
TinyUpseller.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    callToAction: PropTypes.node.isRequired,
    onClickClose: PropTypes.func,
};
export default TinyUpseller;
