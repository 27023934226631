import propTypes from '@styled-system/prop-types';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import { getSystemPropTypes } from "../../../../src/utils";
import * as React from 'react';
export default {
  propTypes,
  PropsTable,
  Example,
  getSystemPropTypes,
  React
};