import React, { useState } from 'react';
import { Box, Avatar } from '@marvelapp/ui';
import {
  ListView,
  ListRow,
  ListText,
  ListRowCell,
  LoaderCellType,
} from '@marvelapp/ui-internal';
import { projectsSetA } from './data';

export function LoadingMoreItemsExample() {
  const [items, setItems] = useState(projectsSetA);
  const [isLoading, setLoading] = useState(false);

  const loadMoreItems = () => {
    return new Promise((resolve) => {
      setLoading(true);

      setTimeout(() => {
        const newItems = [...projectsSetA]; // TODO
        setItems([...items, ...newItems]);
        setLoading(false);
        resolve();
      }, 3000);
    });
  };

  const renderListItem = (item) => (
    <ListRow>
      <ListRowCell>
        <Avatar mr={3} size={36} username={item.owner.username} />
        <ListText kind="bold">{item.title}</ListText>
      </ListRowCell>
    </ListRow>
  );

  const columns = [{ loader: LoaderCellType.AvatarWithText }];

  return (
    <Box borderRadius={2} boxShadow="popover.default" marginTop={4}>
      <ListView
        columns={columns}
        isLoadingRows={isLoading}
        listStyle={{ padding: 6, height: '400px', overflow: 'scroll' }}
        onScrollToBottom={loadMoreItems}
      >
        {items.map(renderListItem)}
      </ListView>
    </Box>
  );
}
