import { Flex, Box, Avatar } from '@marvelapp/ui';
import Example from "../../../../src/components/Example";
import PropsTable from "../../../../src/components/PropsTable";
import * as React from 'react';
export default {
  Flex,
  Box,
  Avatar,
  Example,
  PropsTable,
  React
};