import React from 'react';
import styled from 'styled-components';
import { Flex, Tooltip, Box } from '@marvelapp/ui';
import { ListText } from '../ListText';
import { ListCheckbox, ListCheckboxGutter } from '../ListCheckbox';
import { useListContext } from '../hooks/useListContext';
export function ListHead({ headings }) {
    const listView = useListContext();
    const layout = listView.getRowLayout(headings);
    return React.createElement(ListHeadContent, { headings: headings, layout: layout });
}
export function SelectableListHead({ headings }) {
    const listView = useListContext();
    const layout = listView.getRowLayout(headings);
    let isChecked = null;
    if (listView.isAllSelected()) {
        isChecked = true;
    }
    if (listView.isNoneSelected()) {
        isChecked = false;
    }
    const onChange = (e) => listView.onSelectAll(isChecked === null ? true : e.target.checked);
    return (React.createElement(SelectableListHeadWrapper, null,
        React.createElement(ListCheckboxGutter, { disabled: !listView.selectionAlwaysVisible && !listView.isSelectionActive, isIndented: listView.selectionAlwaysVisible, isVisible: listView.selectionAlwaysVisible || listView.isSelectionActive },
            React.createElement(Tooltip, { content: listView.isAllSelected() ? 'Deselect all' : 'Select all' },
                React.createElement(ListCheckbox, { isChecked: isChecked, onChange: onChange }))),
        React.createElement(ListHeadContent, { headings: headings, isIndented: listView.selectionAlwaysVisible, layout: layout })));
}
function ListHeadContent({ headings, layout, isIndented }) {
    return (React.createElement(ListHeadContentWrapper, { borderBottom: "1px solid", borderColor: "smoke", layout: layout, pl: isIndented ? 52 : 3, pr: 3, py: 4 },
        React.createElement(ListHeadCells, { headings: headings })));
}
function ListHeadCells({ headings }) {
    return headings.map((heading, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    React.createElement(ListText, { key: idx, kind: "bold" }, heading)));
}
const SelectableListHeadWrapper = styled(Flex) `
  position: relative;
  align-items: center;
`;
const ListHeadContentWrapper = styled(Box) `
  align-items: center;
  display: grid;
  flex: 1;
  grid-template-columns: ${(p) => p.layout.gridTemplateColumns};
  grid-gap: ${(p) => p.layout.gridGap};
`;
