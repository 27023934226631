import React from 'react';
import Button from './Button';
import Flex from './Flex';
const getBorderRadius = ({ isFirstChild, isLastChild }) => {
    if (isFirstChild)
        return '99px 0 0 99px';
    if (isLastChild)
        return '0 99px 99px 0';
    return '0px';
};
export default function ButtonGroup({ buttons }) {
    return (React.createElement(Flex, null, buttons.map((button, index) => {
        const { id, isActive, onClick, title, testId } = button;
        const isFirstChild = index === 0;
        const isLastChild = index === buttons.length - 1;
        const borderRaius = getBorderRadius({ isFirstChild, isLastChild });
        return (React.createElement(Button, { borderRadius: borderRaius, "data-testid": testId, key: id, kind: isActive ? 'marvel' : 'ghost', marginRight: "-1px", onClick: onClick, position: isActive ? 'relative' : 'unset', translateOnHover: false }, title));
    })));
}
