import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
export const SearchIcon = styled.div `
  position: relative;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  opacity: ${(props) => (props.isDisabled ? 0.7 : 1)};
  color: ${(props) => props.isSearchOpen ? themeGet('colors.marvel') : themeGet('colors.slate')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};

  &:hover {
    color: ${(props) => props.isDisabled ? 'none' : themeGet('colors.marvel')};
  }
`;
