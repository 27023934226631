import React from 'react';
import { remove, range } from 'lodash-es';
import { Avatar, Tooltip, Flex, Box, ContentLoader } from '@marvelapp/ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import ModalLink from '../Modal/ModalLink';
import MoreCollaborators from './MoreCollaborators';
export default function CollaboratorList({ collaborators, collaboratorsAggregate, disableTooltip, isLoading, ownerPk, shareUrl, testId, tooltipPosition = 'bottom', size = 34, }) {
    const MAX_LENGTH = useResponsiveValue([5, 5, 5, 5, 3, 3, 5]);
    if (isLoading) {
        return (React.createElement(Flex, { "data-testid": `${testId}-loading`, marginRight: "10px" }, range(4).map((loader, index) => (React.createElement(Box, { key: loader, mr: "-6px", position: "relative", zIndex: range(4).length - index },
            React.createElement(ContentLoader, { border: "3px solid", borderColor: "white", borderRadius: 18, height: size + 2, key: loader, kind: "medium", width: size + 2 }))))));
    }
    if (!collaborators)
        return null;
    const sortedCollaborators = getSortedCollaborators(ownerPk, collaborators);
    const moreThanMax = sortedCollaborators.length > MAX_LENGTH;
    const remainingCollaborators = collaboratorsAggregate
        ? collaboratorsAggregate - MAX_LENGTH
        : sortedCollaborators.length - MAX_LENGTH;
    return (React.createElement(ModalLink, { display: ['none', 'block'], mr: 2, to: shareUrl },
        React.createElement(Flex, { alignItems: "center", mr: 1 },
            sortedCollaborators.slice(0, MAX_LENGTH).map((collaborator, index) => {
                return (React.createElement(Box, { key: collaborator.pk, mr: "-6px", position: "relative", zIndex: sortedCollaborators.length - index },
                    React.createElement(Tooltip, { content: disableTooltip ? undefined : collaborator.username, cursor: shareUrl ? 'pointer' : 'initial', display: "block", position: tooltipPosition },
                        React.createElement(Avatar, { borderWidth: 3, email: collaborator.email, pk: collaborator.pk, size: size, src: collaborator.avatarUrl }))));
            }),
            moreThanMax && (React.createElement(Box, { mr: "-6px", position: "relative", zIndex: 1 },
                React.createElement(Tooltip, { content: disableTooltip
                        ? undefined
                        : `And ${remainingCollaborators} more`, cursor: shareUrl ? 'pointer' : 'initial', position: tooltipPosition },
                    React.createElement(MoreCollaborators, { num: remainingCollaborators, size: size })))))));
}
function getSortedCollaborators(ownerPk, collaborators) {
    const collaboratorNodes = collaborators.edges.map(({ node }) => node);
    const owner = remove(collaboratorNodes, ({ pk }) => pk === ownerPk);
    return [...owner, ...collaboratorNodes];
}
