import React from 'react';
import PropTypes from 'prop-types';
import { kinds } from './kinds';
import { MessageElement } from './styles';
/** @param {any} */
function Message({ children, kind, borderWidth, ...props }) {
    return (React.createElement(MessageElement, Object.assign({ borderWidth: borderWidth, pl: borderWidth + 15, pt: "2px" }, kinds[kind], props), children));
}
Message.propTypes = {
    borderWidth: PropTypes.number,
    children: PropTypes.any,
    kind: PropTypes.oneOf(['error', 'information', 'success']),
    /** margin */
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /** margin-top */
    mt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-right */
    mr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-bottom */
    mb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-left */
    ml: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-left and margin-right */
    mx: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-top and margin-bottom */
    my: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding */
    p: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /** padding-top */
    pt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-right */
    pr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-bottom */
    pb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-left */
    pl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-left and padding-right */
    px: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-top and padding-bottom */
    py: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
};
Message.defaultProps = {
    borderWidth: 6,
    kind: 'information',
};
export default Message;
