import React, { useState, useRef, useEffect } from 'react';
import { SEARCH_ICON } from '@marvelapp/theme';
import { useOnClickOutside } from '@marvelapp/hooks';
import EscKeyHandler from '../EscKeyHandler';
import Flex from '../Flex';
import Icon from '../Icon';
import Input from '../Input';
import PinTL from '../PinTL';
import Position from '../Position';
import SearchCloseButton from '../SearchCloseButton';
import { SearchIcon } from './styles';
import Box from '../Box';
export default function SearchInput({ autoFocus, isDisabled, searchTerm = '', onChange, onOpen, onClose, testId = 'search-input-box', onToggle, ...rest }) {
    const [isSearchOpen, setIsSearchOpen] = useState(autoFocus || searchTerm);
    const [inputValue, setInputValue] = useState(searchTerm);
    useEffect(() => {
        if (onToggle) {
            onToggle(isSearchOpen);
        }
    }, [isSearchOpen, onToggle]);
    const textInputRef = useRef();
    const ref = useRef();
    useOnClickOutside(ref, () => {
        if (isSearchOpen && !searchTerm && !isDisabled) {
            closeInput();
            textInputRef.current.blur();
        }
    });
    function onIconClick() {
        if (!isSearchOpen) {
            openInput();
            textInputRef.current.focus();
        }
        else {
            closeInput();
        }
    }
    function handleEscKey() {
        closeInput();
        textInputRef.current.blur();
    }
    function openInput() {
        if (!isDisabled) {
            setIsSearchOpen(true);
            if (onOpen)
                onOpen();
        }
    }
    function closeInput() {
        if (!isDisabled) {
            setIsSearchOpen(false);
            if (searchTerm !== '') {
                onChange('');
                setInputValue('');
            }
            if (onClose)
                onClose();
        }
    }
    return (React.createElement(Box, { ref: ref, width: [1, 1, 'auto'] },
        React.createElement(Position, { position: "relative", width: [1, 1, 'auto'] },
            isSearchOpen && React.createElement(EscKeyHandler, { onEscapeKey: handleEscKey }),
            React.createElement(Position, { bg: "white", position: "absolute", right: isSearchOpen ? '-1px' : 0, top: 0, zIndex: isSearchOpen ? 'popover' : 'unset' },
                React.createElement(Input, Object.assign({ autoFocus: autoFocus, bg: "white", boxShadow: isSearchOpen
                        ? 'input.default'
                        : ['input.default', 'input.default', 'none'], "data-testid": testId, hasTransition: false, onChange: (e) => {
                        onChange(e.target.value);
                        setInputValue(e.target.value);
                    }, pl: 36, pointerEvents: isDisabled ? 'none' : 'all', pr: isSearchOpen ? 3 : 0, ref: textInputRef, size: 2, value: inputValue, width: isSearchOpen ? [1, 1, 200, 310] : [1, 1, 0] }, rest)),
                isSearchOpen && searchTerm && (React.createElement(SearchCloseButton, { onClick: onIconClick })),
                isSearchOpen && (React.createElement(PinTL, null,
                    React.createElement(SearchIcon, { isDisabled: isDisabled, isSearchOpen: isSearchOpen, onClick: onIconClick },
                        React.createElement(Icon, { "data-testid": "search-icon", height: 24, paths: SEARCH_ICON, width: 24 }))))),
            React.createElement(Flex, { alignItems: ['flex-start', 'flex-start', 'center'], flexDirection: ['column', 'column', 'row'] },
                React.createElement(SearchIcon, { "data-testid": "search-icon-wrapper", isDisabled: isDisabled, isSearchOpen: isSearchOpen, onClick: onIconClick },
                    React.createElement(Icon, { "data-testid": "search-icon", height: 24, paths: SEARCH_ICON, width: 24 }))))));
}
