import { getFormattedImageUrl } from '@marvelapp/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ContentLoader from '../ContentLoader';
import Image from '../Image';
import { kinds } from './kinds';
import { AnimationHolder, AvatarWrapper, Border, Online } from './styles';
import { usernameInitials } from './utils';
const GRAVATAR = 'gravatar.com';
/** @param {any} */
function Avatar({ alt, borderWidth, borderColor = 'white', email, bg, size, src, kind, username, isOnline, greyscale, onlineDotBorderColor, ...rest }) {
    const imageProps = {
        url: src,
        width: size,
        height: size,
        cropParams: '',
    };
    let croppedImageUrl = getFormattedImageUrl({
        dpr: 1,
        ...imageProps,
    });
    let croppedImageUrlRetina = getFormattedImageUrl({
        dpr: 2,
        ...imageProps,
    });
    // Temporary hack to remove gravatars
    // The API still returns them but we don't support them anymore
    if (src && src.includes(GRAVATAR)) {
        croppedImageUrl = undefined;
        croppedImageUrlRetina = undefined;
    }
    const initials = usernameInitials(username, email);
    const initialsUrl = `https://marvelapp.com/avatar/${initials}.svg?greyscale=${greyscale}`;
    const hasBorder = borderWidth !== 0;
    const [loadingSuccess, setLoadingSuccess] = useState(false);
    const [loadingFinished, setLoadingFinished] = useState(false);
    return (React.createElement(AvatarWrapper, Object.assign({ bg: kinds[kind].bg, borderRadius: size / 2, "data-testid": "avatar", height: size, width: size }, rest),
        React.createElement(AnimationHolder, { opacity: loadingFinished ? 0 : 1 },
            React.createElement(ContentLoader, { borderRadius: size / 2, height: size, kind: kind, position: "absolute", top: 0, width: size })),
        React.createElement(AnimationHolder, { opacity: loadingFinished && !loadingSuccess ? 0 : 1 },
            React.createElement(Image, { alt: alt, borderRadius: size / 2, finishedLoading: (result) => {
                    setLoadingFinished(true);
                    setLoadingSuccess(result.success);
                }, height: size, objectFit: "cover", position: "absolute", src: croppedImageUrl || initialsUrl, src2x: croppedImageUrlRetina || initialsUrl, testId: "avatar-image", top: 0, width: size })),
        hasBorder && (React.createElement(Border, { border: `${borderWidth}px solid`, borderColor: borderColor, borderRadius: (size + 2) / 2 })),
        isOnline && (React.createElement(Online, { bg: "green", border: "2px solid", borderColor: onlineDotBorderColor }))));
}
/** @type {any} */
Avatar.propTypes = {
    onlineDotBorderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    email: PropTypes.string,
    kind: PropTypes.oneOf(['medium', 'light', 'dark']),
    isOnline: PropTypes.bool,
    greyscale: PropTypes.bool,
    size: PropTypes.any,
    src: PropTypes.string,
    username: PropTypes.string,
};
/** @type {any} */
Avatar.defaultProps = {
    onlineDotBorderColor: 'white',
    borderWidth: 0,
    kind: 'medium',
    greyscale: false,
    isOnline: false,
};
export default Avatar;
