import React, { Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Ordered, Unordered, Unstyled } from './styles';
function List({ children, type, size, ...rest }) {
    const items = Children.map(children, (element) => isValidElement(element) ? cloneElement(element, { size }) : element);
    if (type === 'ordered') {
        return React.createElement(Ordered, Object.assign({}, rest), items);
    }
    if (type === 'ordered') {
        return React.createElement(Unordered, Object.assign({}, rest), items);
    }
    return React.createElement(Unstyled, Object.assign({}, rest), items);
}
List.propTypes = {
    children: PropTypes.any,
    type: PropTypes.oneOf(['ordered', 'unordered']),
};
List.defaultProps = {
    type: 'unordered',
    size: 2,
};
export default List;
