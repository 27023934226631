import React, { Component } from 'react';
import { bool, string } from 'prop-types';
class Selectbox extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            boxTop: 0,
            boxLeft: 0,
            boxWidth: 0,
            boxHeight: 0,
            isBoxSelecting: false,
        };
        this.getRef = () => this.selectbox;
        this.getSelectboxRef = (c) => {
            this.selectbox = c;
        };
    }
    render() {
        const boxStyle = {
            left: this.state.boxLeft,
            top: this.state.boxTop,
            width: this.state.boxWidth,
            height: this.state.boxHeight,
            zIndex: 9000,
            position: this.props.fixedPosition ? 'fixed' : 'absolute',
            cursor: 'default',
        };
        return (React.createElement("div", null, this.state.isBoxSelecting && (React.createElement("div", { className: this.props.className, ref: this.getSelectboxRef, style: boxStyle }))));
    }
}
Selectbox.propTypes = {
    fixedPosition: bool,
    className: string,
};
Selectbox.defaultProps = {
    className: 'selectable-selectbox',
};
export default Selectbox;
