import styled from 'styled-components';
import { border, boxShadow, layout, opacity, position, space, } from 'styled-system';
import { objectFit, objectPosition, filter } from '../customProperties';
export const ImageElement = styled.img `
  ${border};
  ${boxShadow};
  ${filter};
  ${layout};
  ${objectFit};
  ${objectPosition};
  ${opacity};
  ${position};
  ${space};
  transition: opacity 300ms ease-in-out;
`;
