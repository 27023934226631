import styled from 'styled-components';
import { border, color } from 'styled-system';
export const FolderElement = styled.div `
  ${border};
  ${color};
  width: 100%;
  flex: 1;
`;
export const Tab = styled.div `
  ${color};
  width: 40%;
  height: ${(props) => props.tabSize}px;
  border-top-left-radius: ${(props) => props.tabSize}px;
  border-top-right-radius: ${(props) => props.tabSize}px;
`;
export const TabExtension = styled.div `
  ${color};
  width: 100%;
  height: ${(props) => props.tabSize}px;
  border-top-right-radius: ${(props) => props.tabSize}px;
`;
