import styled, { css } from 'styled-components';
import { border, shadow, color, flexbox, layout, space, typography, } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import { hoverColor, hoverBoxShadow, hoverBg, focusColor, focusBoxShadow, focusBg, pointerEvents, resize, } from '../customProperties';
export const inputStyles = css `
  ${border};
  ${shadow};
  ${color};
  ${flexbox};
  ${layout};
  ${pointerEvents};
  ${resize};
  ${space};
  ${typography};

  appearance: none;
  border-width: 0;
  box-sizing: border-box;

  outline-style: none;
  outline-color: transparent;
  outline-offset: 4px;
  outline-style: dashed;
  outline-width: 1px;

  transition-property: all;
  transition-duration: 150ms;
  transition: all 400ms ${themeGet('easings.smooth.string')};

  &:hover {
    ${hoverColor};
    ${hoverBoxShadow};
    ${hoverBg};
  }

  &:focus {
    ${focusColor};
    ${focusBoxShadow};
    ${focusBg};
  }

  &::-webkit-input-placeholder {
    color: ${(props) => themeGet(`colors.${props.placeholderColor}`)};
    opacity: ${(props) => props.placeholderOpacity};
    letter-spacing: -0.2px;
    line-height: inherit;
  }
  &::-moz-placeholder {
    color: ${(props) => themeGet(`colors.${props.placeholderColor}`)};
    opacity: ${(props) => props.placeholderOpacity};
    letter-spacing: -0.2px;
    line-height: inherit;
  }
  &:-ms-input-placeholder {
    color: ${(props) => themeGet(`colors.${props.placeholderColor}`)};
    opacity: ${(props) => props.placeholderOpacity};
    letter-spacing: -0.2px;
    line-height: inherit;
  }
  &:-moz-placeholder {
    color: ${(props) => themeGet(`colors.${props.placeholderColor}`)};
    opacity: ${(props) => props.placeholderOpacity};
    letter-spacing: -0.2px;
    line-height: inherit;
  }

  &::placeholder {
    color: ${(props) => themeGet(`colors.${props.placeholderColor}`)};
    opacity: ${(props) => props.placeholderOpacity};
    letter-spacing: -0.2px;
    line-height: inherit;
  }
`;
export const InputElement = styled.input `
  ${inputStyles};

  ${(props) => !props.hasTransition &&
    css `
      transition: none;
    `};
`;
export const LoaderWrapper = styled.div `
  ${layout};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;
