import React, { Component } from 'react';
import Checkbox from '../Checkbox';
class CheckboxContainer extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            isChecked: this.props.isChecked,
        };
        this.onChange = () => {
            this.setState((state) => ({
                isChecked: !state.isChecked,
            }));
        };
    }
    render() {
        const { isChecked } = this.state;
        const { children } = this.props;
        return (React.createElement(Checkbox, Object.assign({ onChange: this.onChange }, this.props, { isChecked: isChecked }), children));
    }
}
CheckboxContainer.defaultProps = {
    isBulk: false,
};
export default CheckboxContainer;
