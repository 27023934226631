require('prismjs/themes/prism-coy.css');
require('./src/assets/css/fonts.css');

const React = require('react');
const { ThemeProvider } = require('styled-components');
const theme = require('@marvelapp/theme');

const { GlobalStyle } = require('./src/components/GlobalStyle');

exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme.default}>
      <>
        <GlobalStyle color="slate" fontFamily={0} />
        {element}
      </>
    </ThemeProvider>
  );
};
