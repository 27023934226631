import React, { Component } from 'react';
import Clipboard from 'react-clipboard.js';
import { isSupported } from 'clipboard';
import { Button, Position, PinAll, PinCenter, Icon } from '@marvelapp/ui';
import { CHECKBOX_TICK_ICON } from '@marvelapp/theme';
import { Element, SlideAndFade, ScaleAndFade } from './styles';
const IDLE = 'IDLE';
const COPY_SUCCESS = 'COPY_SUCCESS';
const COPY_ERROR = 'COPY_ERROR';
const TRANSITION_ICON = 'icon';
function delay(ms, val = true) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(val), ms);
    });
}
class CopyToClipboard extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            status: IDLE,
        };
        this.unmounting = false;
        this.safeSetState = (updater) => {
            return new Promise((resolve) => {
                if (!this.unmounting) {
                    this.setState(updater, resolve);
                }
            });
        };
        this.onSuccess = () => {
            this.safeSetState({
                status: COPY_SUCCESS,
            }).then(this.resetStatus);
        };
        this.onError = () => {
            this.safeSetState({
                status: COPY_ERROR,
            }).then(this.resetStatus);
        };
        this.resetStatus = () => {
            return delay(this.props.timeout).then(() => this.safeSetState({
                status: IDLE,
            }));
        };
        this.getText = () => {
            return this.props.clipboardText
                ? this.props.clipboardText
                : this.props.text;
        };
    }
    componentWillUnmount() {
        this.unmounting = true;
    }
    render() {
        const { btnText, iconColor, iconHeight, iconWidth, onCopy, transitionType, truncated, wrapperStyle, ...rest } = this.props;
        const { status } = this.state;
        const hasSucceded = status === COPY_SUCCESS;
        const isIcon = transitionType === TRANSITION_ICON;
        return (React.createElement(Clipboard, { component: "div", onClick: onCopy, onError: this.onError, onSuccess: this.onSuccess, "option-text": this.getText, style: wrapperStyle },
            React.createElement(Element, Object.assign({ minWidth: isIcon ? 20 : 48 }, rest), isIcon ? (React.createElement(ScaleTransition, { btnText: btnText, hasSucceded: hasSucceded, iconColor: iconColor, iconHeight: iconHeight, iconWidth: iconWidth, truncated: truncated })) : (React.createElement(SlideTransition, { btnText: btnText, hasSucceded: hasSucceded, iconColor: iconColor, truncated: truncated })))));
    }
}
function ScaleTransition({ btnText, hasSucceded, iconColor, iconHeight, iconWidth, truncated, }) {
    return (React.createElement(Position, null,
        React.createElement(ScaleAndFade, { "data-testid": "copy-to-clipboard-value", opacity: hasSucceded ? 0 : 1, scale: hasSucceded ? 0 : 1, truncated: truncated }, btnText),
        React.createElement(PinCenter, null,
            React.createElement(ScaleAndFade, { opacity: hasSucceded ? 1 : 0, scale: hasSucceded ? 1 : 0 },
                React.createElement(Icon, { color: iconColor, display: "block", height: iconWidth, paths: CHECKBOX_TICK_ICON, width: iconHeight })))));
}
function SlideTransition({ btnText, hasSucceded, truncated }) {
    return (React.createElement(Position, null,
        React.createElement(SlideAndFade, { "data-testid": "copy-to-clipboard-value", opacity: hasSucceded ? 0 : 1, translate: hasSucceded ? -5 : 0, truncated: truncated }, btnText),
        React.createElement(PinAll, null,
            React.createElement(SlideAndFade, { opacity: hasSucceded ? 1 : 0, translate: hasSucceded ? 0 : 5 }, "Copied!"))));
}
CopyToClipboard.defaultProps = {
    timeout: 1000,
    as: Button,
    btnText: 'Copy',
    transitionType: 'text',
    iconColor: 'white',
};
export default CopyToClipboard;
export const isCopyToClipboardSupported = isSupported;
