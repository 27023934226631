import styled from 'styled-components';
import { space, overflow, border, color } from 'styled-system';
export const Ratio = styled.div `
  ${space};
  ${color};
  ${overflow};
  ${border};
  position: relative;
`;
export const Child = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
