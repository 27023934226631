import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import Box from '../Box';
export const Container = styled(motion.div) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;
export const Cover = styled.div `
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: ${(props) => props.distance}px;
  pointer-events: all;

  ${(props) => props.position === 'top' &&
    css `
      bottom: -${props.distance}px;
    `};

  ${(props) => props.position === 'bottom' &&
    css `
      top: -${props.distance}px;
    `};
`;
export const PopoverElement = styled(Box) `
  position: absolute;
  pointer-events: all;

  ${(props) => props.align === 'left' &&
    css `
      left: 0;
    `};

  ${(props) => props.align === 'right' &&
    css `
      right: 0;
    `};

  ${(props) => props.align === 'center' &&
    css `
      left: 50%;
      transform: translateX(-50%);
    `};

  ${(props) => props.position === 'top' &&
    css `
      bottom: 100%;
      margin-bottom: ${props.distance}px;
    `};

  ${(props) => props.position === 'bottom' &&
    css `
      top: 100%;
      margin-top: ${props.distance}px;
    `};

  ${(props) => props.position === 'bottomLeft' &&
    css `
      top: 100%;
      margin-top: ${props.distance}px;
      left: 0;
      transform: none;
    `};
`;
