import { Flex, CheckboxContainer } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import * as React from 'react';
export default {
  Flex,
  CheckboxContainer,
  PropsTable,
  Example,
  React
};