import React from 'react';
import styled from 'styled-components';

import { CopyToClipboard } from '@marvelapp/ui-internal';
import { Box, Text, Heading, Flex, hexToRGB } from '@marvelapp/ui';
import { colors } from '@marvelapp/theme';

export default function PropsTable({ types, marginTop = 4 }) {
  return (
    <>
      <Box
        border="1px solid"
        borderColor="smoke"
        borderRadius={2}
        marginTop={marginTop}
      >
        <Flex borderBottom="1px solid" borderColor="smoke" padding={3}>
          <Box flex={1}>
            <Heading display="block" fontSize={2}>
              Prop
            </Heading>
          </Box>
          <Box flex={1}>
            <Heading display="block" fontSize={2}>
              Type
            </Heading>
          </Box>
          <Box flex={1}>
            <Heading display="block" fontSize={2}>
              Default
            </Heading>
          </Box>
        </Flex>
        {types
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((prop) => (
            <PropItem
              borderBottom="1px solid"
              borderColor="smoke"
              hoverBg="snow"
              key={prop.name}
              padding="12px"
            >
              <Box flex={1}>
                <PropBadge name={prop.name} />
              </Box>
              <Box flex={1}>
                {Array.isArray(prop.type) ? (
                  prop.type.map((type) => (
                    <RegularBadge key={type}>{type}</RegularBadge>
                  ))
                ) : (
                  <RegularBadge>{prop.type}</RegularBadge>
                )}
              </Box>
              <Box alignItems="center" display="flex" flex={1}>
                {prop.default === undefined ? (
                  <Text color="slate" px={2}>
                    –
                  </Text>
                ) : (
                  <RegularBadge>{prop.default}</RegularBadge>
                )}
              </Box>
            </PropItem>
          ))}
      </Box>
    </>
  );
}

const PropBadge = ({ name }) => (
  <CopyToClipboard
    as={Box}
    bg={hexToRGB(colors.orangeExtraExtraLight, 0.4)}
    borderRadius={1}
    btnText={name}
    color="orange"
    cursor="pointer"
    display="inline-block"
    fontFamily={1}
    fontSize={1}
    fontWeight={600}
    hoverBg={hexToRGB(colors.orangeExtraExtraLight, 0.7)}
    iconColor="orangeDark"
    margin={1}
    minWidth={0}
    padding={1}
    text={name}
    textAlign="center"
    transition="smooth.fast"
    transitionType="icon"
  />
);

const RegularBadge = (props) => (
  <Box
    bg="snowDark"
    borderRadius={1}
    color="silver"
    display="inline-block"
    fontFamily={1}
    fontSize={1}
    fontWeight={600}
    iconColor="orangeDark"
    margin={1}
    minWidth={0}
    padding={1}
    textAlign="center"
    {...props}
  />
);

const PropItem = styled(Flex)`
  &:first-child {
    border-radius: ${(props) =>
      `${props.theme.radii[2]}px ${props.theme.radii[2]}px 0 0`};
  }
  &:last-child {
    border-bottom: none;
    border-radius: ${(props) =>
      `0 0 ${props.theme.radii[2]}px ${props.theme.radii[2]}px`};
  }
`;
