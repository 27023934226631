import React, { useRef } from 'react';
import { SelectableGroup } from '../SelectableGroup';
export const SelectableListBoundaryContext = React.createContext({
    dragBoundaryExists: false,
    subscribeToDragSelection: () => null,
    unsubscribeFromDragSelection: () => null,
});
export function SelectableListBoundary({ children, boundaryName, dragTolerance, }) {
    const subscribedListCallback = useRef(null);
    const subscribeToDragSelection = (callback) => {
        if (subscribedListCallback.current) {
            // eslint-disable-next-line no-console
            console.warn(`subscribedListCallback is already defined on boundary ${boundaryName}. You can't subscribe multiple lists to the same boundary.`);
            return;
        }
        subscribedListCallback.current = callback;
    };
    const unsubscribeFromDragSelection = () => {
        subscribedListCallback.current = null;
    };
    const onDragSelectionEnd = (selection) => {
        const subscriberCallback = subscribedListCallback.current;
        if (subscriberCallback) {
            subscriberCallback(selection);
        }
    };
    const listBoundaryContextValue = {
        dragBoundaryExists: true,
        subscribeToDragSelection,
        unsubscribeFromDragSelection,
    };
    return (React.createElement(SelectableListBoundaryContext.Provider, { value: listBoundaryContextValue },
        React.createElement(SelectableGroup, { dragTolerance: dragTolerance, onSelectionEnd: onDragSelectionEnd, width: "inherit" }, children)));
}
