import React from 'react';
import styled from 'styled-components';
import { Text } from '@marvelapp/ui';
const listTextStyleMap = {
    normal: {
        color: 'slate',
        fontSize: 13,
        lineHeight: 1.3,
    },
    bold: {
        color: 'licorice',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1.3,
    },
};
export function BaseListText({ children, className, kind = 'normal', marginBottom, testId, }) {
    const textStyle = listTextStyleMap[kind] || listTextStyleMap.normal;
    return (React.createElement(Text, Object.assign({}, textStyle, { className: className, color: textStyle.color, "data-testid": testId, marginBottom: marginBottom }), children));
}
export const ListText = styled(BaseListText) `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const MainListText = styled(ListText) ``;
