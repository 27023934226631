import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from '../Flex';
const FlexWrap = styled(Flex) `
  flex-wrap: wrap;
  margin-left: ${({ space }) => `-${space}px`};
  width: ${({ space }) => `calc(100% + ${space * 2}px)`};
`;
FlexWrap.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    space: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
};
export default FlexWrap;
