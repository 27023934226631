import React, { forwardRef } from 'react';
import { TextareaElement } from './styles';
import { inputSizes } from './sizes';
import { inputKinds } from '../Input/kinds';
const Textarea = forwardRef(({ disabled = false, display = 'block', fontFamily = 0, fontWeight = 400, isLoading = false, kind = 'default', size = 1, ...props }, ref) => {
    const allProps = {
        ...inputSizes[size],
        ...inputKinds[kind],
        ...props,
    };
    return (React.createElement(TextareaElement, Object.assign({ disabled: disabled, display: display, fontFamily: fontFamily, fontWeight: fontWeight, isLoading: isLoading }, allProps, { ref: ref })));
});
export default Textarea;
