import React from 'react';
import { Box, ContentLoader, Flex, Heading, Row, Text } from '@marvelapp/ui';
import Highlighter from './Highlighter';
export default function SimpleListItem({ badge, children, hasSingleAvatar, heading, highlightHeading, hoverBg = 'white', isLastChild, isLoading, onClick, px = 2, renderCollaborators, renderImage, searchWords = [], status, subheading, }) {
    return (React.createElement(Row, { cursor: onClick ? 'pointer' : 'default', "data-testid": "person-row", hoverBg: hoverBg, isLastChild: isLastChild, onClick: onClick, px: px },
        React.createElement(Flex, { alignItems: "center", justifyContent: "space-between" },
            hasSingleAvatar ? (React.createElement(Flex, { alignItems: "center", flex: 1, minWidth: 0 },
                React.createElement(Avatar, { isLoading: isLoading, renderImage: renderImage, status: status }),
                React.createElement(NameAndMeta, { badge: badge, heading: heading, highlightHeading: highlightHeading, isLoading: isLoading, searchWords: searchWords, subheading: subheading }))) : (React.createElement(NameAndMeta, { heading: heading, highlightHeading: highlightHeading, isLoading: isLoading, searchWords: searchWords, subheading: subheading })),
            React.createElement(Flex, { alignItems: "center", ml: 2 },
                renderCollaborators && (
                // eslint-disable-next-line
                React.createElement(Box, { borderRight: "1px solid", borderColor: "smoke", pr: 2, mr: 16 }, renderCollaborators({ isLoading }))),
                children))));
}
function Avatar({ isLoading, renderImage, status }) {
    if (isLoading) {
        return (React.createElement(Box, { marginRight: 3 },
            React.createElement(ContentLoader, { borderRadius: 20, height: 40, kind: "medium", width: 40 })));
    }
    return React.createElement(Box, { marginRight: 3 }, renderImage(status));
}
function NameAndMeta({ badge, heading, highlightHeading, isLoading, searchWords, subheading, }) {
    if (isLoading) {
        return (React.createElement(Box, { flex: 1, minWidth: 0 },
            React.createElement(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", marginBottom: 2, width: 0.8 }),
            React.createElement(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", width: 0.4 })));
    }
    return (React.createElement(Box, { flex: 1, minWidth: 0, wordWrap: "break-word" },
        React.createElement(Flex, { alignItems: "center", marginBottom: "4px" },
            React.createElement(Heading, { fontSize: 2, fontWeight: 500, lineHeight: 1.3, truncated: true }, highlightHeading ? (React.createElement(Highlighter, { searchWords: searchWords, textToHighlight: heading })) : (heading))),
        React.createElement(Text, { "data-testid": "row-subtext", fontSize: 2, lineHeight: 1.3, truncated: true },
            badge && `${badge} •`,
            ' ',
            React.createElement(Highlighter, { searchWords: searchWords, textToHighlight: subheading }))));
}
