import { Flex, FlexWrap, Text, Box } from '@marvelapp/ui';
import { Color } from '@marvelapp/ui-internal';
import { colors, space } from '@marvelapp/theme';
import * as React from 'react';
export default {
  Flex,
  FlexWrap,
  Text,
  Box,
  Color,
  colors,
  space,
  React
};