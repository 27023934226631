import styled from 'styled-components';
import { border, shadow, color, space, layout } from 'styled-system';
import themeGet from '@styled-system/theme-get';
export const CardElement = styled.div `
  ${border};
  ${color};
  ${layout};
  ${shadow};
  ${space};
  position: relative;
  transition: all 400ms ${themeGet('easings.smooth.string')};
`;
