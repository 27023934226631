import querystring from 'query-string';
var FASTLY = 'freetls.fastly.net/serve';
export function getFormattedImageUrl(options) {
    var url = options.url, _options$dpr = options.dpr, dpr = _options$dpr === void 0 ? window.devicePixelRatio : _options$dpr, _options$width = options.width, width = _options$width === void 0 ? 300 : _options$width, height = options.height, _options$aspectRatio = options.aspectRatio, aspectRatio = _options$aspectRatio === void 0 ? '1:1' : _options$aspectRatio, _options$cropParams = options.cropParams, cropParams = _options$cropParams === void 0 ? ',smart' : _options$cropParams; // If not from fastly we can't crop it, so just fallback
    if (!url || url.indexOf(FASTLY) < 0)
        return url; // Crop and resize using Fastly:
    // Some API images like avatars might come back with already params on the images
    // therefore we don't wanna remove those and just replace some of them.
    var parsed = querystring.parseUrl(url);
    parsed.query.dpr = "" + dpr || parsed.query.dpr || undefined;
    parsed.query.width = "" + width;
    parsed.query.height = "" + height;
    parsed.query.crop = aspectRatio + cropParams || parsed.query.crop || undefined;
    if (parsed.query.crop && parsed.query.width) {
        // When using crop you can't have a height and width
        parsed.query.height = undefined;
    }
    if (!parsed.query.width || !parsed.query.height) {
        // when using fit you need both height and width
        parsed.query.fit = undefined;
    }
    var queryStringified = querystring.stringify(parsed.query, {
        encode: false
    });
    return parsed.url + "?" + queryStringified;
}
