import { Box, Flex, PinBL, PinBR, PinCenter, PinTL, PinTR, Relative } from '@marvelapp/ui';
import * as React from 'react';
export default {
  Box,
  Flex,
  PinBL,
  PinBR,
  PinCenter,
  PinTL,
  PinTR,
  Relative,
  React
};