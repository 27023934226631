import React from 'react';
import styled from 'styled-components';
import { Flex } from '@marvelapp/ui';
export function ListRowActions({ children, areVisible }) {
    return (React.createElement(ActionsContainer, { className: "list-row-actions", onClick: (e) => e.stopPropagation(), opacity: areVisible ? 1 : 0 }, children));
}
export const ActionsContainer = styled(Flex) `
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: ${(props) => props.theme.space[2]}px;
  }
`;
