import React from 'react';
import { isObject } from 'lodash-es';
import Link from '../Link';
export default function ModalLink(props) {
    const { to: origTo } = props;
    let to;
    if (!origTo) {
        // Seems there are a bunch of places where 'to' is undefined until some data
        // or a feature flag is loaded. Should probably fix them ...
        to = origTo;
    }
    else if (isObject(origTo)) {
        to = {
            ...origTo,
            state: {
                ...origTo.state,
                modal: true,
            },
        };
    }
    else {
        const hashIndex = origTo.indexOf('#');
        const searchIndex = origTo.indexOf('?');
        if (hashIndex !== -1 || searchIndex !== -1) {
            throw new Error("To use ModalLink with hash or search params use the object form of the 'to' argument");
        }
        to = {
            pathname: origTo,
            state: { modal: true },
        };
    }
    return React.createElement(Link, Object.assign({}, props, { to: to }));
}
