import React from 'react';
import styled from 'styled-components';
import { Box } from '@marvelapp/ui';
import CopyToClipboard from './CopyToClipboard';
export default function Color({ bg, color = 'white', isFirst = false, isLast = false, isMiddle = true, name, }) {
    return (React.createElement(Outer, { bg: bg, borderRadius: getBorderRadius({ isFirst, isLast, isMiddle }), cursor: "pointer", hasShadow: color === 'white', height: 70, position: "relative" },
        React.createElement(Inner, { alignItems: "center", color: color, display: "flex", fontSize: 14, fontWeight: 500, height: "100%", letterSpacing: 0, lineHeight: 1.3, width: 1 },
            React.createElement(CopyToClipboard, { as: Box, btnText: name, color: color, display: "block", fontWeight: 500, height: 70, hoverColor: color, lineHeight: "70px", px: 16, text: bg, whiteSpace: "nowrap", width: 1 }))));
}
const Inner = styled(Box).attrs({ transition: 'smooth.medium' }) `
  opacity: 0;
  text-shadow: ${(props) => props.hasShadow ? '0 1px 1px rgba(0, 0, 0, 0.12)' : 'none'};
`;
const Outer = styled(Box) `
  &:hover ${Inner} {
    opacity: 1;
  }
`;
function getBorderRadius({ isFirst, isLast, isMiddle }) {
    if (isFirst) {
        return '4px 4px 0 0';
    }
    if (isLast) {
        return '0 0 4px 4px';
    }
    if (isMiddle) {
        return '0px';
    }
    return '4px';
}
