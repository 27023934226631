import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { easings } from '@marvelapp/theme';
import { Cover, Container, PopoverElement } from './styles';
import Position from '../Position';
export default function Popover(props) {
    const { align = 'center', animateOnMount = true, boxShadow = 'popover.default', children, distance = 15, isActive, position = 'top', showOn = 'click', zIndex, ...rest } = props;
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const toggleVisibility = () => {
        setIsPopoverVisible(isPopoverVisible);
    };
    const onMouseEnter = () => {
        setIsPopoverVisible(true);
    };
    const onMouseLeave = () => {
        setIsPopoverVisible(false);
    };
    const isVisible = showOn === 'custom' ? isActive : isPopoverVisible;
    const hoverProps = showOn === 'hover' && {
        onMouseLeave,
        onMouseEnter,
    };
    const clickProps = showOn === 'click' && {
        onClick: toggleVisibility,
    };
    const animation = animateOnMount
        ? {
            animate: {
                scale: 1,
                opacity: 1,
                y: '0%',
                transition: {
                    duration: 0.35,
                    ease: easings.smooth.array,
                },
            },
            initial: {
                scale: 0.95,
                opacity: 1,
                y: position === 'top' ? '10%' : '-10%',
            },
            exit: {
                scale: 0.95,
                opacity: 0,
                y: position === 'top' ? '10%' : '-10%',
                transition: {
                    duration: 0.35,
                    ease: easings.smooth.array,
                },
            },
            transition: {
                duration: 0.35,
                ease: easings.smooth.array,
            },
        }
        : {
            animate: {},
            initial: {},
            exit: {},
            transition: {},
        };
    return (React.createElement(Position, Object.assign({ position: showOn === 'custom' ? 'unset' : 'relative', width: 1, zIndex: zIndex }, hoverProps),
        showOn !== 'custom' && React.createElement("span", Object.assign({}, clickProps), children[0]),
        React.createElement(AnimatePresence, null, isVisible && (React.createElement(Container, { animate: animation.animate, exit: animation.exit, initial: animation.initial, key: "popover", transition: animation.transition },
            React.createElement(PopoverElement, Object.assign({ align: align, bg: "white", borderRadius: 2, boxShadow: boxShadow, distance: distance, position: position }, rest),
                showOn !== 'custom' ? children[1] : children,
                React.createElement(Cover, { distance: distance, position: position })))))));
}
