import React from 'react';
import styled from 'styled-components';
import Flex from '../Flex';
import Text from '../Text';
import Box from '../Box';
import sizes from './sizes';
export default function LiNumbered({ size, children, number, ...rest }) {
    return (React.createElement(Li, Object.assign({ as: "li" }, rest),
        React.createElement(Flex, { alignItems: "flex-stretch", mb: sizes[size].spacing },
            React.createElement(Flex, { alignItems: "center", border: "1px solid", borderColor: "smoke", borderRadius: sizes[size].iconBorderRadius, fontSize: sizes[size].iconFontSize, fontWeight: 600, height: sizes[size].iconSize, justifyContent: "center", minHeight: sizes[size].iconSize, minWidth: sizes[size].iconSize, pt: "1px", textAlign: "center", width: sizes[size].iconSize }, number),
            React.createElement(Flex, { alignItems: "center" },
                React.createElement(Text, { fontSize: sizes[size].fontSize, pl: sizes[size].iconMarginRight }, children)))));
}
const Li = styled(Box) `
  list-style: none;
`;
LiNumbered.defaultProps = {
    size: 2,
    number: 1,
};
