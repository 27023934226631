import { useContext, useEffect, useCallback } from 'react';
import { SelectableListBoundaryContext } from '../SelectableListBoundary';
export function useDragBoundary(selectionMap, onSelectionChange) {
    const boundary = useContext(SelectableListBoundaryContext);
    const onDragSelection = useCallback((rowKeys) => {
        const updatedSelection = { ...selectionMap };
        rowKeys.forEach((selectedKey) => {
            updatedSelection[selectedKey] = !selectionMap[selectedKey];
        });
        onSelectionChange(updatedSelection);
    }, [selectionMap, onSelectionChange]);
    useEffect(() => {
        boundary.subscribeToDragSelection(onDragSelection);
        return boundary.unsubscribeFromDragSelection;
    }, [boundary, onDragSelection]);
    return boundary.dragBoundaryExists;
}
