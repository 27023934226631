import React from 'react';
import styled from 'styled-components';
import { Flex, Checkbox } from '@marvelapp/ui';
const LIST_VIEW_CHECKBOX_SIZE = 20;
export function ListCheckbox({ isChecked, onChange, testId }) {
    return (React.createElement(Checkbox, { "data-testid": testId, display: "flex", isChecked: isChecked, onChange: onChange, size: LIST_VIEW_CHECKBOX_SIZE }));
}
export function ListCheckboxGutter({ children, isVisible, disabled, isIndented = true, }) {
    return (React.createElement(CheckboxGutter, { alignItems: "center", className: "list-checkbox-gutter not-selectable", disabled: disabled, height: "100%", isVisible: isVisible, pl: isIndented && 3, position: "absolute", pr: !isIndented && 2, transform: !isIndented && 'translateX(-100%)' }, children));
}
const CheckboxGutter = styled(Flex) `
  opacity: 0;

  ${(p) => p.isVisible &&
    `
    opacity: 1;
  `}

  ${(p) => p.disabled &&
    `
    pointer-events: none;
  `}
`;
