import React from 'react';
import { Rnd } from 'react-rnd';
function Floaty({ children, defaultWidth = 'auto', defaultHeight = 'auto', defaultX = 0, defaultY = 0, zIndex = 0, bounds = 'parent', pointerEvents, ...rest }) {
    // this is needed for marvel-docs because during the build Rnd is not available, which breaks the build
    // please don't remove
    if (!Rnd) {
        return null;
    }
    return (React.createElement(Rnd, Object.assign({ bounds: bounds, default: {
            x: defaultX,
            y: defaultY,
            width: defaultWidth,
            height: defaultHeight,
        }, onDragStart: (e) => {
            e.preventDefault(); // Avoids cursor selection of elements behind the Floaty
        }, onResizeStart: (e) => {
            e.preventDefault(); // Avoids cursor selection of elements behind the Floaty
        }, style: { zIndex, pointerEvents } }, rest), children));
}
export default Floaty;
