import React, { useContext } from 'react';
export const ListContext = React.createContext({});
export function useListContext() {
    const listContext = useContext(ListContext);
    const colWidths = listContext.columns.map((c) => c.width);
    return {
        getRowLayout: (children) => buildRowLayout(children, colWidths),
        ...listContext,
    };
}
function buildRowLayout(children, colWidths) {
    const childrenCount = React.Children.count(children);
    const hasColWidths = colWidths && colWidths.length;
    const colWidthsStyle = hasColWidths
        ? colWidths.join(' ')
        : `repeat(${childrenCount}, 1fr)`;
    const layout = {
        gridTemplateColumns: colWidthsStyle,
        gridGap: '8px',
    };
    return layout;
}
