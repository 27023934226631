export function getDocumentScroll() {
    const documentScrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    const documentScrollLeft = Math.max(window.pageXOffset, document.documentElement.scrollLeft, document.body.scrollLeft);
    return { documentScrollTop, documentScrollLeft };
}
/**
 * Given a node, get everything needed to calculate its boundaries
 * @param  {HTMLElement} node
 * @return {Object}
 */
export function getBoundsForNode(node, containerScroll = { scrollTop: 0, scrollLeft: 0 }) {
    const { scrollTop, scrollLeft } = containerScroll;
    const { documentScrollTop, documentScrollLeft } = getDocumentScroll();
    const rect = node.getBoundingClientRect();
    return {
        top: rect.top + documentScrollTop + scrollTop,
        left: rect.left + documentScrollLeft + scrollLeft,
        offsetWidth: node.offsetWidth,
        offsetHeight: node.offsetHeight,
        computedWidth: rect.width,
        computedHeight: rect.height,
    };
}
/**
 * Given offsets, widths, and heights of two objects, determine if they collide (overlap).
 * @param  {int} aTop    The top position of the first object
 * @param  {int} aLeft   The left position of the first object
 * @param  {int} bTop    The top position of the second object
 * @param  {int} bLeft   The left position of the second object
 * @param  {int} aWidth  The width of the first object
 * @param  {int} aHeight The height of the first object
 * @param  {int} bWidth  The width of the second object
 * @param  {int} bHeight The height of the second object
 * @return {bool}
 */
const coordsCollide = (aTop, aLeft, bTop, bLeft, aWidth, aHeight, bWidth, bHeight, tolerance = 0) => {
    return !(aTop + aHeight - tolerance < bTop ||
        // 'a' top doesn't touch 'b' bottom
        aTop + tolerance > bTop + bHeight ||
        // 'a' right doesn't touch 'b' left
        aLeft + aWidth - tolerance < bLeft ||
        // 'a' left doesn't touch 'b' right
        aLeft + tolerance > bLeft + bWidth);
};
/**
 * Given two objects containing "top", "left", "offsetWidth" and "offsetHeight"
 * properties, determine if they collide.
 * @param  {Object|HTMLElement} a
 * @param  {Object|HTMLElement} b
 * @return {bool}
 */
export function doObjectsCollide(a, b, tolerance, delta = 1) {
    const aObj = a instanceof HTMLElement ? getBoundsForNode(a) : a;
    const bObj = b instanceof HTMLElement ? getBoundsForNode(b) : b;
    if (delta === 1) {
        return coordsCollide(aObj.top, aObj.left, bObj.top, bObj.left, aObj.offsetWidth, aObj.offsetHeight, bObj.offsetWidth, bObj.offsetHeight, tolerance);
    }
    // We have a delta and we need to compute the new values
    return coordsCollide(aObj.top, aObj.left, bObj.top, bObj.left, aObj.computedWidth, aObj.computedHeight, bObj.computedWidth, bObj.computedHeight, tolerance);
}
/**
 * Checks whether a node is within
 * a root nodes tree
 */
export function isNodeInRoot(node, root) {
    while (node) {
        if (node === root) {
            return true;
        }
        // eslint-disable-next-line no-param-reassign
        node = node.parentNode;
    }
    return false;
}
export function detectLeftButton(event) {
    if (event.metaKey || event.ctrlKey || event.altKey) {
        return false;
    }
    if ('buttons' in event) {
        return event.buttons === 1;
    }
    if ('which' in event) {
        return event.which === 1;
    }
    return event.button === 1;
}
export function preventEvent(target, type) {
    const preventHandler = (e) => {
        target.removeEventListener(type, preventHandler, true);
        e.preventDefault();
        e.stopPropagation();
    };
    target.addEventListener(type, preventHandler, true);
}
/**
 * Used to return event object with desktop (non-touch) format of event
 * coordinates, regardless of whether the action is from mobile or desktop.
 */
export function getDesktopEventCoords(e) {
    if (e.pageX === undefined || e.pageY === undefined) {
        // Touch-device
        if (e.targetTouches[0] !== undefined &&
            e.targetTouches[0].pageX !== undefined) {
            // For touchmove
            e.pageX = e.targetTouches[0].pageX;
            e.pageY = e.targetTouches[0].pageY;
        }
        else if (e.changedTouches[0] !== undefined &&
            e.changedTouches[0].pageX !== undefined) {
            // For touchstart
            e.pageX = e.changedTouches[0].pageX;
            e.pageY = e.changedTouches[0].pageY;
        }
    }
    return e;
}
