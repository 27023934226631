import styled, { css } from 'styled-components';
import themeGet from '@styled-system/theme-get';
import theme from '@marvelapp/theme';
export const Element = styled.div ``;
export const SlideAndFade = styled.div `
  will-change: transform;
  opacity: ${(props) => props.opacity};
  transform: translateY(${(props) => props.translate}px);
  transition: opacity 400ms ${themeGet('easings.smooth.string')},
    transform 400ms ${themeGet('easings.smooth.string')};

  ${(props) => props.truncated &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;
SlideAndFade.defaultProps = {
    theme,
};
export const ScaleAndFade = styled.div `
  will-change: transform;
  opacity: ${(props) => props.opacity};
  transform: scale(${(props) => props.scale});
  transition: opacity 400ms ${themeGet('easings.smooth.string')},
    transform 400ms ${themeGet('easings.smooth.string')};

  ${(props) => props.truncated &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;
ScaleAndFade.defaultProps = {
    theme,
};
