import { Icon, Grid, Box, Flex, Text } from '@marvelapp/ui';
import { ADD_TO_FOLDER_ICON } from '@marvelapp/theme';
import Example from "../../../../src/components/Example";
import IconsGrid from "../../../../src/components/IconsGrid";
import PropsTable from "../../../../src/components/PropsTable";
import * as React from 'react';
export default {
  Icon,
  Grid,
  Box,
  Flex,
  Text,
  ADD_TO_FOLDER_ICON,
  Example,
  IconsGrid,
  PropsTable,
  React
};