import { Box, Flex, Textarea } from '@marvelapp/ui';
import { TextareaWithLabel } from '@marvelapp/ui-internal';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import * as React from 'react';
export default {
  Box,
  Flex,
  Textarea,
  TextareaWithLabel,
  PropsTable,
  Example,
  React
};