import React from 'react';
import PropTypes from 'prop-types';
import { convertRatioToNumber } from './utils';
import { Ratio, Child } from './styles';
export default function AspectRatio({ ratio, children, ...props }) {
    const numericRatio = convertRatioToNumber(ratio);
    const paddingTop = numericRatio === 0 ? '100%' : `${100 / numericRatio}%`;
    return (React.createElement(Ratio, Object.assign({ pt: paddingTop }, props),
        React.createElement(Child, null, children)));
}
AspectRatio.propTypes = {
    children: PropTypes.node,
    ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
AspectRatio.defaultProps = {
    ratio: 4 / 3,
};
