import React from 'react';
import { Box, Button, Tooltip, ContentLoader } from '@marvelapp/ui';
export default function PersonButton({ badgeText, badgeTooltip, buttonKind, buttonText, isDisabled, isLoading: isRowLoading, onButtonClick, isButtonLoading, }) {
    const tooltipContent = isDisabled
        ? "You don't have the correct permissions"
        : undefined;
    if (isRowLoading) {
        return (React.createElement(ContentLoader, { borderRadius: 12, height: 24, kind: "medium", width: 68 }));
    }
    if (badgeText) {
        return (React.createElement(Tooltip, { content: badgeTooltip, position: "left", whiteSpace: "wrap", width: 145 },
            React.createElement(Box, { bg: "snowDark", borderRadius: 15, cursor: "default", "data-testid": "person-badge", fontSize: 1, fontWeight: 500, height: 24, lineHeight: "24px", minWidth: 66, textAlign: "center" }, badgeText)));
    }
    return (React.createElement(Tooltip, { content: tooltipContent, position: "left" },
        React.createElement(Button, { "data-testid": "person-button", isDisabled: isDisabled, isLoading: isButtonLoading, kind: buttonKind, minWidth: 68, onClick: onButtonClick, px: 12, size: 0 }, buttonText)));
}
