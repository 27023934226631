import { Box, Text, Flex, Grid } from '@marvelapp/ui';
import { space } from '@marvelapp/theme';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import * as React from 'react';
export default {
  Box,
  Text,
  Flex,
  Grid,
  space,
  PropsTable,
  Example,
  React
};