const kinds = {
    default: {
        hoverThumbColor: 'black',
        hoverTrackColor: 'smokeExtraDark',
        progressColor: 'silver',
        thumbBoxShadow: 'none',
        thumbColor: 'slate',
        trackColor: 'smokeDark',
    },
    whiteTransparent: {
        hoverThumbColor: 'white',
        hoverTrackColor: 'transparent',
        progressColor: 'transparent',
        thumbBoxShadow: 'card.raised',
        thumbColor: 'white',
        trackColor: 'transparent',
    },
    green: {
        hoverThumbColor: 'black',
        hoverTrackColor: 'greenDark',
        progressColor: 'orange',
        thumbBoxShadow: 'none',
        thumbColor: 'slate',
        trackColor: 'green',
    },
};
export default kinds;
