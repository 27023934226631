import styled from 'styled-components';
import { space, shadow, style } from 'styled-system';
import themeGet from '@styled-system/theme-get';
const trackColor = style({
    prop: 'trackColor',
    cssProperty: 'background',
    key: 'colors',
});
const progressColor = style({
    prop: 'progressColor',
    cssProperty: 'background',
    key: 'colors',
});
const thumbColor = style({
    prop: 'thumbColor',
    cssProperty: 'background',
    key: 'colors',
});
const thumbBoxShadow = style({
    prop: 'thumbBoxShadow',
    cssProperty: 'boxShadow',
    key: 'shadows',
});
const hoverThumbColor = style({
    prop: 'hoverThumbColor',
    cssProperty: 'background',
    key: 'colors',
});
const hoverTrackColor = style({
    prop: 'hoverTrackColor',
    cssProperty: 'background',
    key: 'colors',
});
export const RangeSliderElement = styled.input `
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${(props) => props.thumbHeight}px;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  appearance: none;
  outline: none;
  cursor: pointer;
  ${space};
  ${shadow};

  &:hover {
    &::-webkit-slider-runnable-track {
      ${hoverTrackColor};
    }
    &::-moz-range-track {
      ${hoverTrackColor};
    }
    &::-ms-track {
      ${hoverTrackColor};
    }
    &::-moz-range-thumb {
      transform: scale(1.2);
      ${hoverThumbColor};
    }
    &::-webkit-slider-thumb {
      transform: scale(1.2);
      ${hoverThumbColor};
    }
    &::-ms-thumb {
      transform: scale(1.2);
      ${hoverThumbColor};
    }
  }

  &::-webkit-slider-thumb {
    appearance: none;
  }

  &::-webkit-slider-runnable-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: ${(props) => props.sliderHeight}px;
    border-radius: ${(props) => props.sliderHeight / 2}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
    ${trackColor};
  }

  &::-moz-range-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: ${(props) => props.sliderHeight}px;
    border-radius: ${(props) => props.sliderHeight / 2}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
    ${trackColor};
  }

  &::-ms-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: ${(props) => props.sliderHeight}px;
    border-radius: ${(props) => props.sliderHeight / 2}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
    ${trackColor};
  }

  &::-moz-range-progress {
    height: ${(props) => props.sliderHeight}px;
    border-radius: ${(props) => props.sliderHeight / 2}px;
    ${progressColor};
  }

  &::-ms-fill-lower {
    height: ${(props) => props.sliderHeight}px;
    border-radius: ${(props) => props.sliderHeight / 2}px;
    ${progressColor};
  }

  &::-webkit-slider-thumb {
    margin-top: -${(props) => (props.thumbHeight - props.sliderHeight) / 2}px;
    box-sizing: border-box;
    border: none;
    width: ${(props) => props.thumbWidth}px;
    height: ${(props) => props.thumbHeight}px;
    border-radius: ${(props) => props.thumbBorderRadius}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
    ${thumbColor};
    ${thumbBoxShadow};
  }

  &::-moz-range-thumb {
    box-sizing: border-box;
    border: none;
    width: ${(props) => props.thumbWidth}px;
    height: ${(props) => props.thumbHeight}px;
    border-radius: ${(props) => props.thumbBorderRadius}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
    ${thumbColor};
    ${thumbBoxShadow};
  }

  &::-ms-thumb {
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    width: ${(props) => props.thumbWidth}px;
    height: ${(props) => props.thumbHeight}px;
    border-radius: ${(props) => props.thumbBorderRadius}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
    ${thumbColor};
    ${thumbBoxShadow};
  }

  &::-ms-tooltip {
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
`;
