import { Box, Button, Image, Upseller } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import videoImage from "../../../../content/docs/components/assets/video.png";
import videoImage2x from "../../../../content/docs/components/assets/video@2x.png";
import * as React from 'react';
export default {
  Box,
  Button,
  Image,
  Upseller,
  PropsTable,
  Example,
  videoImage,
  videoImage2x,
  React
};