import React from 'react';
import { IMAGE_ICON } from '@marvelapp/theme';
import Flex from '../Flex';
import Icon from '../Icon';
import Position from '../Position';
import { getCardKind } from './kinds';
import { CardElement } from './styles';
function Card({ children, hasImage, iconSize, isDragged, isSelected, ...props }) {
    return (React.createElement(CardElement, Object.assign({ boxShadow: getCardKind(isSelected, isDragged) }, props),
        !hasImage && (React.createElement(Position, { bottom: 0, left: 0, position: "absolute", right: 0, top: 0 },
            React.createElement(Flex, { alignItems: "center", height: "100%", justifyContent: "center" },
                React.createElement(Icon, { fill: "smokeExtraDark", height: iconSize, paths: IMAGE_ICON, width: iconSize })))),
        children));
}
Card.defaultProps = {
    bg: 'snow',
    borderRadius: 2,
    hasImage: true,
    iconSize: 32,
};
export default Card;
