import React, { Component } from 'react';
import { number, bool, array, string, func, node, object } from 'prop-types';
import SelectBox from './SelectBox';
import SelectableGroupContext from './Context';
import { detectLeftButton, doObjectsCollide, getBoundsForNode, getDesktopEventCoords, getDocumentScroll, isNodeInRoot, preventEvent, } from './utils';
const noop = () => { };
export default class SelectableGroup extends Component {
    constructor() {
        super(...arguments);
        this.defaultContainerStyle = {
            position: 'relative',
        };
        /* eslint-disable lines-between-class-members */
        this.mouseDownStarted = false;
        this.mouseMoveStarted = false;
        this.mouseUpStarted = false;
        this.mouseDownData = null;
        this.registry = new Map();
        this.selectedItems = new Set();
        this.ignoreCheckCache = new Map();
        this.getContextValue = () => ({
            selectable: {
                register: this.registerSelectable,
                unregister: this.unregisterSelectable,
                getScrolledContainer: () => this.scrollContainer,
            },
        });
        this.setScollTop = (e) => {
            const { scrollTop } = this.scrollContainer;
            this.checkScrollTop(e, scrollTop);
            this.checkScrollBottom(e, scrollTop);
        };
        this.checkScrollTop = (e, currentTop) => {
            const { minimumSpeedFactor, scrollSpeed } = this.props;
            const offset = this.scrollBounds.top - e.clientY;
            if (offset > 0 || e.clientY < 0) {
                const newTop = currentTop - Math.max(offset, minimumSpeedFactor) * scrollSpeed;
                this.scrollContainer.scrollTop = newTop;
            }
        };
        this.checkScrollBottom = (e, currentTop) => {
            const { minimumSpeedFactor, scrollSpeed } = this.props;
            const offset = e.clientY - this.scrollBounds.bottom;
            if (offset > 0 || e.clientY > window.innerHeight) {
                const newTop = currentTop + Math.max(offset, minimumSpeedFactor) * scrollSpeed;
                this.scrollContainer.scrollTop = Math.min(newTop, this.maxScroll);
            }
        };
        this.updateRegistry = () => {
            const containerScroll = {
                scrollTop: this.scrollContainer.scrollTop,
                scrollLeft: this.scrollContainer.scrollLeft,
            };
            this.registry.forEach((selectableItem) => {
                selectableItem.registerSelectable(containerScroll);
            });
        };
        this.registerSelectable = (key, selectableItem) => {
            this.registry.set(key, selectableItem);
        };
        this.unregisterSelectable = (key) => {
            this.registry.delete(key);
            this.selectedItems.delete(key);
        };
        this.applyContainerScroll = (value, scroll) => value + scroll;
        this.openSelectBox = (event) => {
            const e = getDesktopEventCoords(event);
            const dragDelta = Math.max(Math.abs(e.pageX - this.mouseDownData.boxLeft), Math.abs(e.pageY - this.mouseDownData.boxTop));
            if (dragDelta <= this.props.dragTolerance)
                return;
            this.setScollTop(e);
            if (this.mouseMoveStarted)
                return;
            if (!this.selectStarted) {
                this.props.onSelectionStart(event);
                this.selectStarted = true;
            }
            this.mouseMoveStarted = true;
            this.mouseMoved = true;
            const { scrollTop } = this.scrollContainer;
            const eventTop = e.pageY;
            const eventLeft = e.pageX;
            const { documentScrollTop, documentScrollLeft } = getDocumentScroll();
            const top = this.applyContainerScroll(eventTop - this.scrollBounds.top, scrollTop - documentScrollTop);
            let boxTop = this.applyContainerScroll(this.mouseDownData.boxTop - this.scrollBounds.top, this.mouseDownData.scrollTop - documentScrollTop);
            const boxHeight = boxTop - top;
            boxTop = Math.min(boxTop - boxHeight, boxTop);
            const bowWidth = this.mouseDownData.boxLeft - eventLeft;
            const leftContainerRelative = this.mouseDownData.boxLeft - this.scrollBounds.left;
            const boxLeft = this.applyContainerScroll(Math.min(leftContainerRelative - bowWidth, leftContainerRelative), -documentScrollLeft);
            this.selectBox.setState({
                isBoxSelecting: true,
                boxWidth: Math.abs(bowWidth),
                boxHeight: Math.abs(boxHeight),
                boxLeft,
                boxTop,
            }, () => {
                this.updateSelecting();
                this.mouseMoveStarted = false;
            });
        };
        this.updateSelecting = () => {
            const selectBox = this.selectBox.getRef();
            if (!selectBox)
                return;
            const selectBoxBounds = getBoundsForNode(selectBox);
            selectBoxBounds.top += this.scrollContainer.scrollTop;
            selectBoxBounds.left += this.scrollContainer.scrollLeft;
            this.selectItems({
                ...selectBoxBounds,
                offsetWidth: selectBoxBounds.offsetWidth || 1,
                offsetHeight: selectBoxBounds.offsetHeight || 1,
            });
        };
        this.selectItems = (selectBoxBounds) => {
            const { tolerance } = this.props;
            this.registry.forEach((item, key) => {
                this.processItem(key, item, tolerance, selectBoxBounds);
            });
        };
        this.mouseDown = (event) => {
            const e = getDesktopEventCoords(event);
            if (this.mouseDownStarted || this.props.disabled || !detectLeftButton(e)) {
                return;
            }
            if (isInteractive(e.target)) {
                return;
            }
            this.updateWhiteListNodes();
            if (this.inIgnoreList(e.target)) {
                this.mouseDownStarted = false;
                return;
            }
            this.mouseDownStarted = true;
            this.mouseUpStarted = false;
            if (!this.props.globalMouse &&
                !isNodeInRoot(e.target, this.selectableGroup)) {
                const offsetData = getBoundsForNode(this.selectableGroup);
                const collides = doObjectsCollide({
                    top: offsetData.top,
                    left: offsetData.left,
                    bottom: offsetData.offsetHeight,
                    right: offsetData.offsetWidth,
                }, {
                    top: e.pageY,
                    left: e.pageX,
                    offsetWidth: 0,
                    offsetHeight: 0,
                });
                if (!collides) {
                    return;
                }
            }
            this.updateRootBounds();
            this.updateRegistry();
            this.mouseDownData = {
                boxLeft: e.pageX,
                boxTop: e.pageY,
                scrollTop: this.scrollContainer.scrollTop,
                scrollLeft: this.scrollContainer.scrollLeft,
                target: e.target,
            };
            e.preventDefault();
            document.addEventListener('mousemove', this.openSelectBox);
            document.addEventListener('touchmove', this.openSelectBox);
            document.addEventListener('mouseup', this.mouseUp);
            document.addEventListener('touchend', this.mouseUp);
        };
        this.mouseUp = (event) => {
            if (this.mouseUpStarted)
                return;
            this.mouseUpStarted = true;
            this.mouseDownStarted = false;
            this.removeTempEventListeners();
            if (!this.mouseDownData)
                return;
            const e = getDesktopEventCoords(event);
            if (this.mouseMoved || !isNodeInRoot(e.target, this.rootNode)) {
                if (e.which === 1 && this.mouseDownData.target === e.target) {
                    preventEvent(e.target, 'click');
                }
                this.selectBox.setState({
                    isBoxSelecting: false,
                    boxWidth: 0,
                    boxHeight: 0,
                });
            }
            if (this.mouseMoved) {
                this.props.onSelectionEnd([...this.selectedItems], event);
            }
            else {
                this.props.onSelectionClear();
            }
            this.selectedItems.clear();
            this.mouseMoved = false;
            this.selectStarted = false;
        };
        this.getGroupRef = (c) => {
            this.selectableGroup = c;
        };
        this.getSelectBoxRef = (c) => {
            this.selectBox = c;
        };
    }
    /* eslint-enable lines-between-class-members */
    componentDidMount() {
        this.rootNode = this.selectableGroup;
        this.scrollContainer =
            document.querySelector(this.props.scrollContainer) || this.rootNode;
        this.rootNode.addEventListener('mousedown', this.mouseDown);
        this.rootNode.addEventListener('touchstart', this.mouseDown);
    }
    componentWillUnmount() {
        this.rootNode.removeEventListener('mousedown', this.mouseDown);
        this.rootNode.removeEventListener('touchstart', this.mouseDown);
        this.removeTempEventListeners();
    }
    removeTempEventListeners() {
        document.removeEventListener('mousemove', this.openSelectBox);
        document.removeEventListener('touchmove', this.openSelectBox);
        document.removeEventListener('mouseup', this.mouseUp);
        document.removeEventListener('touchend', this.mouseUp);
    }
    updateRootBounds() {
        this.scrollBounds = this.scrollContainer.getBoundingClientRect();
        this.maxScroll =
            this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight;
    }
    processItem(key, item, tolerance, selectBoxBounds) {
        if (this.inIgnoreList(item.node)) {
            return;
        }
        const isCollided = doObjectsCollide(selectBoxBounds, item.bounds, tolerance, this.props.delta);
        if (isCollided) {
            this.selectedItems.add(key);
        }
    }
    inIgnoreList(target) {
        if (this.ignoreCheckCache.get(target) !== undefined) {
            return this.ignoreCheckCache.get(target);
        }
        const shouldBeIgnored = this.ignoreListNodes.some((ignoredNode) => target === ignoredNode || ignoredNode.contains(target));
        this.ignoreCheckCache.set(target, shouldBeIgnored);
        return shouldBeIgnored;
    }
    updateWhiteListNodes() {
        this.ignoreListNodes = [
            ...document.querySelectorAll(['.not-selectable', ...this.props.ignoreList].join(', ')),
        ];
    }
    render() {
        const Tag = this.props.component;
        return (React.createElement(SelectableGroupContext.Provider, { value: this.getContextValue() },
            React.createElement(Tag, { className: this.props.className, ref: this.getGroupRef, style: Object.assign({}, this.defaultContainerStyle, this.props.style) },
                React.createElement(SelectBox, { className: this.props.selectBoxClassName, fixedPosition: this.props.fixedPosition, ref: this.getSelectBoxRef }),
                this.props.children)));
    }
}
SelectableGroup.propTypes = {
    globalMouse: bool,
    ignoreList: array,
    scrollSpeed: number,
    minimumSpeedFactor: number,
    className: string,
    selectBoxClassName: string,
    style: object,
    disabled: bool,
    delta: number,
    /**
     * Scroll container selector
     */
    scrollContainer: string,
    /**
     * Event that will fire when the selection starts.
     */
    onSelectionStart: func,
    /**
     * Event that will fire when the selection ends.
     * Passes an array of keys with the items that are selected.
     */
    onSelectionEnd: func,
    /**
     * The component that will represent the Selectable DOM node
     */
    component: node,
    /**
     * Amount of forgiveness an item will offer to the selectBox before registering
     * a selection, i.e. if only 1px of the item is in the selection, it shouldn't be
     * included.
     */
    tolerance: number,
    /**
     * In some cases, it the bounding box may need fixed positioning, if your layout
     * is relying on fixed positioned elements, for instance.
     * @type boolean
     */
    fixedPosition: bool,
};
SelectableGroup.defaultProps = {
    component: 'div',
    tolerance: 0,
    globalMouse: false,
    ignoreList: [],
    scrollSpeed: 0.25,
    minimumSpeedFactor: 60,
    onSelectionStart: noop,
    onSelectionEnd: noop,
    onSelectionClear: noop,
    disabled: false,
    delta: 1,
    dragTolerance: 0,
};
function isInteractive(target) {
    // Probably don't want to count mousing down on interactive elements as the
    // start of a drag select
    const blacklist = [
        'BUTTON',
        // Forms
        'INPUT',
        'TEXTAREA',
        'SELECT',
        'OPTION',
        'OPTGROUP',
        'PROGRESS',
        'METER',
        'DATALIST',
        // Image and multimedia
        'VIDEO',
        'AUDIO',
        'AREA',
        'MAP',
        'TRACK',
    ];
    return blacklist.indexOf(target.tagName) !== -1;
}
