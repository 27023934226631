export const getColors = (color) => {
    switch (color) {
        case 'orange':
            return {
                tab: 'orangeExtraLight',
                folder: 'orangeExtraExtraLight',
            };
        case 'green':
            return {
                tab: 'greenLight',
                folder: 'greenExtraLight',
            };
        case 'blue':
            return {
                tab: 'marvelLight',
                folder: 'marvelExtraLight',
            };
        case 'yellow':
            return {
                tab: 'yellowExtraLight',
                folder: 'yellowExtraExtraLight',
            };
        case 'purple':
            return {
                tab: 'purpleExtraLight',
                folder: 'purpleExtraExtraLight',
            };
        case 'teal':
            return {
                tab: 'tealLight',
                folder: 'tealExtraLight',
            };
        case 'snow':
            return {
                tab: 'smoke',
                folder: 'snowExtraDark',
            };
        default:
            return {
                tab: 'marvelLight',
                folder: 'marvelExtraLight',
            };
    }
};
