const sizes = [
    {
        borderRadius: 10,
        fontSize: 1,
        height: 20,
        px: 2,
    },
    {
        borderRadius: 15,
        fontSize: 12,
        height: 24,
        px: 2,
    },
];
export default sizes;
