import React from 'react';
import Box from '../Box';
import { getRowBg } from './color';
function Row({ children, hoverBg, isDisabled, isLastChild, isLoading, isSelected, pointerEvents, borderTop, bg, ...props }) {
    const rowBg = getRowBg(isSelected, isLoading, isDisabled);
    return (React.createElement(Box, Object.assign({ bg: bg || rowBg.bg, borderBottom: isLastChild ? 'none' : '1px solid', borderTop: borderTop, 
        // eslint-disable-next-line
        borderColor: "smoke", hoverBg: hoverBg || rowBg.hoverBg, pointerEvents: pointerEvents, px: 3, py: 18 }, props), children));
}
Row.defaultProps = {
    isDisabled: false,
    isLastChild: false,
    isLoading: false,
    isSelected: false,
};
export default Row;
