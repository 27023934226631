export const projectsSetA = [
  {
    pk: 1,
    title: 'SnapCat',
    type: 'Prototype',
    owner: { username: 'Rachel' },
    image:
      'https://marvel-live.freetls.fastly.net/serve/2017/6/1baafb7dd5f494492326f24326678ce.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 2,
    title: 'Mono',
    type: 'User Test',
    owner: { username: 'Mark' },
    image:
      'https://marvel-live.freetls.fastly.net/serve/2018/1/c12a79a49c3044d08ba74ca90aac8eb3.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 3,
    title: 'Pro Finder',
    type: 'User Test',
    owner: { username: 'Yavor' },
    image:
      'https://marvel-live.freetls.fastly.net/serve/2019/11/b3e966273124547a0aeb29a62071f7b.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 4,
    title: 'Marvel Fashion',
    type: 'Prototype',
    owner: { username: 'Max' },
    image:
      'https://marvel-live.freetls.fastly.net/serve/2019/11/42e9924a96a34c3db31955a26cbe7dbe.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 5,
    title: 'Cat agency',
    type: 'Prototype',
    owner: { username: 'Murat' },
    image:
      'https://marvel-live.freetls.fastly.net/serve/2017/11/457ac67f9fee45f38ce68781beaa7b95.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
];

export const projectsSetB = [
  {
    pk: 6,
    title: 'User testing',
    image:
      'https://marvel-live.freetls.fastly.net/serve/2019/6/3079c1d5a9bf48d6b5fa1b82bbf04782.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 7,
    title: 'My Bank',
    image:
      'https://marvel-live.freetls.fastly.net/serve/2019/10/2d7cfb2b733c426e92b2b0b33abf2e62.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 8,
    title: 'Shop',
    image:
      'https://marvel-live.freetls.fastly.net/serve/2018/2/c9bf6ac73d464f98854d2e315c0c4bf4.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
  {
    pk: 9,
    title: 'Crafty',
    image:
      'https://marvel-live.freetls.fastly.net/serve/2017/11/5b16c27e73e34e1e82b15ca96d132b4b.png?crop=1:1,offset-x0,offset-y0&dpr=2&fake=.png&quality=95&width=300',
  },
];
