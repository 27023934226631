import React from 'react';
import PropTypes from 'prop-types';
import Flex from '../Flex';
import Box from '../Box';
import AspectRatio from '../AspectRatio';
import { FolderElement, Tab, TabExtension } from './styles';
import { getColors } from './utils';
function Folder({ color, tabSize, testId, ratio }) {
    const colors = getColors(color);
    return (React.createElement("div", { "data-testid": testId, style: { width: '100%' } },
        React.createElement(AspectRatio, { ratio: ratio },
            React.createElement(Flex, { flexDirection: "column", height: "100%" },
                React.createElement(Box, { px: `${tabSize}px`, width: 1 },
                    React.createElement(Tab, { bg: tabSize > 4 ? colors.tab : colors.folder, ml: 10, tabSize: tabSize }),
                    tabSize > 4 && (React.createElement(TabExtension, { bg: colors.tab, ml: 10, tabSize: tabSize }))),
                React.createElement(FolderElement, { bg: colors.folder, borderRadius: `${tabSize}px` })))));
}
Folder.propTypes = {
    color: PropTypes.string,
    tabSize: PropTypes.number,
    ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Folder.defaultProps = {
    tabSize: 8,
    ratio: 4 / 3,
};
export default Folder;
