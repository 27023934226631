import styled from 'styled-components';
import { color, space } from 'styled-system';
import themeGet from '@styled-system/theme-get';
const DISTANCE = 3;
export const InputElement = styled.input `
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;

  ${space};

  &:checked + label {
    ${color};
  }

  &:checked + label:after {
    left: calc(100% - ${DISTANCE}px);
    transform: translateX(-100%);
  }
`;
export const LabelElement = styled.label `
  ${color};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

  cursor: pointer;
  text-indent: -9999px;
  width: ${(props) => props.size - DISTANCE * 2}px;
  height: ${(props) => props.size / 2}px;
  display: block;
  position: relative;
  border-radius: ${(props) => props.size / 2}px;
  transition: all 300ms ${themeGet('easings.smooth.string')};

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: ${(props) => props.size / 2 - DISTANCE * 2}px;
    height: ${(props) => props.size / 2 - DISTANCE * 2}px;
    background-color: white;
    border-radius: ${(props) => props.size / 2 - DISTANCE * 2}px;
    transition: all 300ms ${themeGet('easings.smooth.string')};
  }

  &:active:after {
    width: ${(props) => props.size / 2}px;
  }
`;
