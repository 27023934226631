import React from 'react';
import { CLOSE_ICON } from '@marvelapp/theme';
import Box from '../Box';
import Text from '../Text';
import Icon from '../Icon';
import Flex from '../Flex';
import PinTR from '../PinTR';
function HintBar({ bg, image, title, description, callToAction, onClickClose, ...rest }) {
    const maxWidthTextContent = 650;
    return (React.createElement(Flex, Object.assign({ bg: bg, borderRadius: 2, flexDirection: ['column', 'column', 'row'], minHeight: 72, pb: [5, 5, 3], pl: [5, 5, 0], position: "relative", pr: [5, 5, onClickClose ? 40 : 5], pt: [4, 4, 3], width: "100%" }, rest),
        image && (React.createElement(Flex, { alignItems: "center", justifyContent: "center", mx: 5 }, image)),
        React.createElement(Flex, { display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", ml: [0, 0, image ? 0 : 5], my: 1 },
            React.createElement(Text, { as: "span", color: "white", fontSize: 3, fontWeight: 500, maxWidth: maxWidthTextContent, textAlign: ['center', 'center', 'left'] }, title),
            React.createElement(Text, { as: "span", color: "white", fontSize: 2, maxWidth: maxWidthTextContent, opacity: 0.8, textAlign: ['center', 'center', 'left'] }, description)),
        callToAction && (React.createElement(Flex, { alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", ml: [0, 0, 2], mt: [2, 2, 0] },
            React.createElement(Box, { ml: 2 }, callToAction))),
        onClickClose && (React.createElement(PinTR, { top: 6 },
            React.createElement(Box, { cursor: "pointer", onClick: onClickClose },
                React.createElement(Icon, { color: "white", display: "block", hoverColor: "snowDark", mr: 2, paths: CLOSE_ICON }))))));
}
HintBar.defaultProps = {
    bg: 'purple',
};
export default HintBar;
