export default [
    {
        fontSize: 1,
        spacing: 1,
        iconMarginRight: 1,
        iconSize: 18,
    },
    {
        fontSize: [1, 2],
        spacing: [1, 2],
        iconMarginRight: [1, 2],
        iconSize: [18, 24],
    },
    {
        fontSize: [2, 3],
        spacing: [2, 3],
        iconMarginRight: [2, 3],
        iconSize: [24, 28],
    },
    {
        fontSize: [3, 4],
        spacing: [3, 4],
        iconMarginRight: [3, 4],
        iconSize: [28, 35],
    },
];
