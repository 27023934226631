import { Box } from '@marvelapp/ui';
import { CollaboratorList, collaborators } from '@marvelapp/ui-internal';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import * as React from 'react';
export default {
  Box,
  CollaboratorList,
  collaborators,
  PropsTable,
  Example,
  React
};