import { Box, Button, Image, HintBar } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import sketchIcon from "../../../../content/docs/components/assets/sketch.png";
import sketchIcon2x from "../../../../content/docs/components/assets/sketch@2x.png";
import * as React from 'react';
export default {
  Box,
  Button,
  Image,
  HintBar,
  PropsTable,
  sketchIcon,
  sketchIcon2x,
  React
};