import React from 'react';
import { Tooltip } from 'react-lightweight-tooltip';
import { getTooltipStyle } from './styles';
import { positions } from './positions';
/** @param {any} */
function StyledTooltip({ children, content, cursor, display, fontWeight, padding, pointerEvents, position, whiteSpace, width, ...props }) {
    if (!content)
        return children;
    return (React.createElement(Tooltip, Object.assign({ content: content, styles: getTooltipStyle({
            cursor,
            display,
            fontWeight,
            padding,
            pointerEvents,
            position: positions[position],
            whiteSpace,
            width,
        }) }, props), children));
}
StyledTooltip.defaultProps = {
    position: 'top',
    cursor: 'pointer',
    display: 'inline-block',
};
export default StyledTooltip;
