import React, { useRef, useEffect, useState } from 'react';
export function ScrollToBottomTrigger({ onTrigger, observerOptions = {} }) {
    const callbackPromise = useRef(null);
    const triggerRef = useRef(null);
    const [inView, setInView] = useState(false);
    useEffect(() => {
        const options = {
            root: null,
            ...observerOptions,
        };
        const observerCallback = (entries) => {
            const hasInViewEntry = !!entries.find((entry) => entry.isIntersecting);
            setInView(hasInViewEntry);
        };
        const observer = new IntersectionObserver(observerCallback, options);
        const maybeTrigger = triggerRef.current;
        if (maybeTrigger) {
            observer.observe(maybeTrigger);
        }
        return () => observer.disconnect();
    }, []);
    useEffect(() => {
        if (inView) {
            // Check if there is already a callback that was fired. We don't want to
            // trigger it again until it resolved to avoid lots of potential data fetching
            // We do not call again if we are in the middle of async callback
            if (callbackPromise.current && !callbackPromise.isResolved) {
                return;
            }
            const maybePromise = onTrigger();
            // If the callback returns a Promise object, we store it.
            // The state of the promise is checked if there is another attempt to call the callback.
            if (maybePromise instanceof Promise) {
                callbackPromise.current = maybePromise;
                maybePromise.finally(() => {
                    callbackPromise.current = null;
                });
            }
        }
    }, [inView]);
    return onTrigger ? React.createElement("div", { className: "trigger", ref: triggerRef }) : null;
}
