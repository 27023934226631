import React, { Component, createRef } from 'react';
import { getBoundsForNode } from './utils';
import SelectableGroupContext from './Context';
const createSelectable = (WrappedComponent) => { var _a; return _a = class SelectableItem extends Component {
        constructor() {
            super(...arguments);
            this.nodeRef = createRef();
            this.registerSelectable = (containerScroll) => {
                const { selectableKey, isSelectable } = this.props;
                if (!isSelectable)
                    return;
                this.bounds = getBoundsForNode(this.nodeRef.current, containerScroll);
                this.context.selectable.register(selectableKey, this);
            };
        }
        componentDidMount() {
            this.registerSelectable();
        }
        componentDidUpdate(prevProps) {
            if (prevProps.selectableKey !== this.props.selectableKey) {
                this.registerSelectable();
            }
        }
        componentWillUnmount() {
            const { selectableKey } = this.props;
            this.context.selectable.unregister(selectableKey);
        }
        render() {
            return React.createElement(WrappedComponent, Object.assign({}, this.props, { selectableRef: this.nodeRef }));
        }
    },
    _a.contextType = SelectableGroupContext,
    _a; };
export default createSelectable;
