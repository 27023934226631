import React from 'react';
import { TRIANGLE_DOWN_ICON } from '@marvelapp/theme';
import Position from '../Position';
import Icon from '../Icon';
import Loader from '../Loader';
import { Arrow, SelectElement } from './styles';
import { selectSizes, loaderSizes } from './sizes';
import kinds from './kinds';
/** @param {any} */
function Select({ size, kind, children, disabled, defaultValue, isLoading, placeholder, showPlaceholder, ...props }) {
    return (React.createElement(Position, { position: "relative" },
        React.createElement(SelectElement, Object.assign({ as: "select", defaultValue: defaultValue, pointerEvents: disabled ? 'none' : 'all', showPlaceholder: showPlaceholder }, selectSizes[size], kinds[kind], props),
            React.createElement("option", { "data-testid": "select-placeholder", disabled: true, hidden: true, value: defaultValue }, defaultValue),
            children),
        React.createElement(Arrow, { color: "silver", height: selectSizes[size].height, width: selectSizes[size].height }, isLoading ? (React.createElement(Loader, Object.assign({}, loaderSizes[size]))) : (React.createElement(Icon, Object.assign({ paths: TRIANGLE_DOWN_ICON }, loaderSizes[size]))))));
}
/** @type {any} */
Select.defaultProps = {
    bg: 'white',
    border: 'none',
    disabled: false,
    kind: 'ghost',
    size: 1,
    width: 1,
};
export default Select;
