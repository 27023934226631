import React from 'react';
import PropTypes from 'prop-types';
import { CLOSE_ICON } from '@marvelapp/theme';
import Box from '../Box';
import Text from '../Text';
import Flex from '../Flex';
import Icon from '../Icon';
import PinTR from '../PinTR';
function Upseller({ image, onClickClose, title, subtitle, callToAction, ...rest }) {
    return (React.createElement(Flex, Object.assign({ alignItems: "center", bg: "white", borderRadius: 3, boxShadow: "button.ghost", flexDirection: "column", justifyContent: "center", pb: 5, position: "relative", pt: 4, px: 4, width: 280 }, rest),
        React.createElement(Box, null, image),
        React.createElement(Box, { py: 3 },
            React.createElement(Text, { color: "licorice", fontSize: 3, fontWeight: 600, textAlign: "center" }, title),
            subtitle && (React.createElement(Text, { color: "black", fontSize: 2, pt: 1, textAlign: "center" }, subtitle))),
        callToAction,
        onClickClose && (React.createElement(PinTR, { top: 6 },
            React.createElement(Box, { cursor: "pointer", "data-testid": "upsell-close", onClick: onClickClose },
                React.createElement(Icon, { color: "silver", display: "block", hoverColor: "black", mr: 2, paths: CLOSE_ICON }))))));
}
Upseller.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    callToAction: PropTypes.node.isRequired,
    onClickClose: PropTypes.func,
};
export default Upseller;
