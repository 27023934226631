import React from 'react';
import { Box, Avatar } from '@marvelapp/ui';
import {
  ListView,
  ListRow,
  ListText,
  ListRowCell,
} from '@marvelapp/ui-internal';
import { projectsSetA } from './data';

export function BasicListExample() {
  const renderListItem = (item) => (
    <ListRow>
      <ListRowCell>
        <Avatar mr={3} size={36} username={item.owner.username} />
        <ListText kind="bold">{item.title}</ListText>
      </ListRowCell>
    </ListRow>
  );

  return (
    <Box borderRadius={2} boxShadow="popover.default" marginTop={4} padding={6}>
      <ListView>{projectsSetA.map(renderListItem)}</ListView>
    </Box>
  );
}
