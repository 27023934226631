import { Box, Button, Image, Text, Flex, Floaty, Upseller } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import moveImage from "../../../../content/docs/components/assets/move.png";
import moveImage2x from "../../../../content/docs/components/assets/move@2x.png";
import tourImage from "../../../../content/docs/components/assets/tour.png";
import tourImage2x from "../../../../content/docs/components/assets/tour@2x.png";
import * as React from 'react';
export default {
  Box,
  Button,
  Image,
  Text,
  Flex,
  Floaty,
  Upseller,
  PropsTable,
  moveImage,
  moveImage2x,
  tourImage,
  tourImage2x,
  React
};