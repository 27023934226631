import styled from 'styled-components';
import { flex, layout, space } from 'styled-system';
import { SelectableGroup } from '@marvelapp/react-selectable';
import { hexToRGB } from '@marvelapp/ui';
const StyledSelectableGroup = styled(SelectableGroup) `
  ${flex}
  ${space}
  ${layout}
  .selectBox {
    border: ${(props) => `1px solid ${props.theme.colors.green}`};
    background-color: ${(props) => `${hexToRGB(props.theme.colors.green, 0.2)}`};
  }
`;
const StyledNonSelectableGroup = styled.div `
  ${flex}
  ${space}
  ${layout}
  .selectBox {
    border: ${(props) => `1px solid ${props.theme.colors.green}`};
    background-color: ${(props) => `${hexToRGB(props.theme.colors.green, 0.2)}`};
  }
`;
StyledSelectableGroup.defaultProps = {
    selectBoxClassName: 'selectBox',
};
export { StyledSelectableGroup as SelectableGroup, StyledNonSelectableGroup as NonSelectableGroup, };
