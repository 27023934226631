export function convertRatioToNumber(ratio) {
    if (typeof ratio === 'string') {
        let numerator;
        let denominator;
        try {
            [numerator, denominator] = ratio.split('-').map((n) => parseInt(n, 10));
        }
        catch (e) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(`Unable to parse ${ratio}`);
            }
            return 1;
        }
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn(`Warning: AspectRatio expects a number. Replace <AspectRatio ratio={${ratio}}> with <AspectRatio ratio={${numerator} / ${denominator}}`);
        }
        return numerator / denominator;
    }
    return ratio;
}
