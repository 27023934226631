import { Box, Flex, Input } from '@marvelapp/ui';
import { InputWithLabel } from '@marvelapp/ui-internal';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import * as React from 'react';
export default {
  Box,
  Flex,
  Input,
  InputWithLabel,
  PropsTable,
  Example,
  React
};