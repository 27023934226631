import React from 'react';
import styled from 'styled-components';

import { Box } from '@marvelapp/ui';

export default function Example({
  children,
  marginTop = 4,
  withPadding = true,
  ...rest
}) {
  return (
    <StyledBox
      border="1px solid"
      borderColor="smoke"
      borderRadius="5px 5px 0 0 "
      marginTop={marginTop}
      padding={withPadding ? 3 : 0}
      {...rest}
    >
      {children}
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  position: relative;

  &:after {
    height: 10px;
    display: block;
    position: absolute;
    bottom: -11px;
    left: -1px;
    right: -1px;
    content: '';
    background-color: ${(props) => props.theme.colors.snow};
    border-left: 1px solid ${(props) => props.theme.colors.smoke};
    border-right: 1px solid ${(props) => props.theme.colors.smoke};
  }
`;
