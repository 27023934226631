import React, { useState } from 'react';
import { Box, Avatar, SearchInput } from '@marvelapp/ui';
import {
  ListView,
  ListRow,
  ListText,
  ListRowCell,
} from '@marvelapp/ui-internal';
import { projectsSetA } from './data';

export function WithFiltersExample() {
  const [visibleItems, setVisibleItems] = useState(projectsSetA);
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchTermChange = (term) => {
    setSearchTerm(term);

    if (term) {
      // Filter by title matching search term
      const filteredItems = visibleItems.filter(
        (item) => item.title.toLowerCase().indexOf(term.toLowerCase()) > -1
      );
      setVisibleItems(filteredItems);
    } else {
      setVisibleItems(projectsSetA);
    }
  };

  const renderListItem = (item) => (
    <ListRow>
      <ListRowCell>
        <Avatar mr={3} size={36} username={item.owner.username} />
        <ListText kind="bold">{item.title}</ListText>
      </ListRowCell>
    </ListRow>
  );

  return (
    <Box borderRadius={2} boxShadow="popover.default" marginTop={4} padding={6}>
      <SearchInput onChange={onSearchTermChange} value={searchTerm} />
      <ListView>{visibleItems.map(renderListItem)}</ListView>
    </Box>
  );
}
