import React from 'react';
import theme, { CHECKBOX_TICK_ICON, MINUS_ICON } from '@marvelapp/theme';
import Box from '../Box';
import Icon from '../Icon';
import { Control, Input, Indicator, PinAll } from './styles';
export default function Checkbox({ bg, border, borderColor, borderRadius, borderWidth, boxShadow, children, color, display, fontSize, hoverColor, iconColor, isChecked, isDisabled, kind, mr, onChange, size, testId, type = 'checkbox', ...rest }) {
    const isRadio = type === 'radio';
    const isIndeterminate = isChecked === null;
    return (React.createElement(Control, { color: color, cursor: isDisabled ? 'default' : 'pointer', "data-testid": testId, display: display, fontSize: fontSize, height: size, hoverColor: hoverColor, isDisabled: isDisabled, lineHeight: `${size}px`, mr: mr, pl: children ? size + size / 2 : size },
        React.createElement(Input, Object.assign({ checked: isChecked, onChange: onChange, readOnly: !onChange, type: type }, rest)),
        React.createElement(Indicator, { borderRadius: isRadio ? '50%' : borderRadius, borderWidth: borderWidth, height: size, isChecked: isChecked, isIndeterminate: isIndeterminate, kind: isDisabled ? 'disabled' : kind, size: size, width: size }),
        React.createElement(PinAll, { height: size, isActive: isChecked, opacity: isChecked ? 1 : 0, width: size }, isRadio ? (React.createElement(Box, { bg: iconColor, borderRadius: "50%", boxShadow: "popover.default", height: size / 2 - size / 10, width: size / 2 - size / 10 })) : (React.createElement(Icon, { color: iconColor, height: size, paths: CHECKBOX_TICK_ICON, width: size }))),
        React.createElement(PinAll, { color: kind === 'defaultGreenReversed' ? 'white' : 'silver', height: size, isActive: isIndeterminate, width: size },
            React.createElement(Icon, { height: size, paths: MINUS_ICON, width: size })),
        children));
}
Checkbox.defaultProps = {
    theme,
    borderRadius: 1,
    borderWidth: 1,
    color: 'slate',
    display: 'inline-block',
    hoverColor: 'licorice',
    iconColor: 'white',
    isChecked: false,
    isDisabled: false,
    kind: 'defaultGreen',
    size: 24,
};
