import styled from 'styled-components';
import { color, space, layout } from 'styled-system';
import theme from '@marvelapp/theme';
export const MessageElement = styled.div `
  ${space};
  ${layout};
  position: relative;

  &:after {
    ${color};

    position: absolute;
    content: '';
    top: 0;
    left: 0;

    height: 100%;
    width: ${(props) => props.borderWidth}px;
    border-radius: ${(props) => props.borderWidth / 2}px;
  }
`;
MessageElement.defaultProps = {
    theme,
};
