import React, { useState } from 'react';
import { Box, Avatar, Button } from '@marvelapp/ui';
import {
  SelectableListView,
  ListRowActions,
  SelectableListRow,
  ListRowCell,
  ListText,
} from '@marvelapp/ui-internal';
import { projectsSetA } from './data';

export function ListWithHeadersExample() {
  const [selectionMap, setSelectionMap] = useState({});

  const onSelectionChange = (val) => setSelectionMap(val);

  const renderItem = (item) => (
    <SelectableListRow rowKey={item.pk}>
      <ListRowCell>
        <Avatar mr={3} size={36} username={item.owner.username} />
        <ListText kind="bold">{item.title}</ListText>
      </ListRowCell>
      <ListRowCell>
        <ListText>Hello there</ListText>
      </ListRowCell>
      <ListRowActions>
        <Button kind="ghost">Some Action</Button>
        <Button kind="danger">Delete</Button>
      </ListRowActions>
    </SelectableListRow>
  );

  const columns = [
    { heading: 'User', width: '1fr' },
    { heading: 'Greeting', width: '1fr' },
    { heading: '', width: '1fr' },
  ];

  return (
    <Box borderRadius={2} boxShadow="popover.default" marginTop={4} padding={6}>
      <SelectableListView
        columns={columns}
        onSelectionChange={onSelectionChange}
        selectionMap={selectionMap}
      >
        {projectsSetA.map(renderItem)}
      </SelectableListView>
    </Box>
  );
}
