import { colors, fontSizes, radii, zIndices } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
export function getTooltipStyle({ cursor, display, fontWeight, padding = '6px 10px 4px', pointerEvents, position, whiteSpace = 'nowrap', width, }) {
    return {
        wrapper: {
            cursor,
            display,
            fontWeight,
            zIndex: 'unset',
        },
        content: {
            backgroundColor: 'transparent',
            color: colors.white,
            display: 'block',
            fontSize: fontSizes[1],
            lineHeight: 1.5,
            padding: 0,
            whiteSpace,
            width,
        },
        tooltip: {
            backgroundColor: hexToRGB(colors.licorice, 0.9),
            borderRadius: radii[1],
            display: 'block',
            margin: 0,
            minWidth: 0,
            zIndex: zIndices.tooltip,
            padding,
            pointerEvents,
            ...position.tooltip,
        },
        arrow: {
            ...position.arrow,
        },
        gap: {
            ...position.gap,
        },
    };
}
