import React from 'react';
import { Text, Flex, Box, Heading, Badge } from '@marvelapp/ui';
function SettingsItem({ actions, children, description, headingSize = 2, isBeta, maxWidth = 500, mb, title, }) {
    return (React.createElement(Box, { borderBottom: "1px solid", borderColor: "smoke", mb: mb, py: 4 },
        React.createElement(Flex, null,
            React.createElement(Box, { flex: 1, pr: 3, py: 2 },
                React.createElement(Heading, { alignItems: "center", display: "flex", fontSize: headingSize, fontWeight: "500", htmlFor: 1, lineHeight: 1.3, tag: "label" },
                    title,
                    isBeta && (React.createElement(Badge, { fontSize: 0, fontWeight: 600, height: "16px", kind: "ghost", lineHeight: "16px", marginLeft: 2, minWidth: 26, paddingTop: "1px", px: "6px" }, "Beta"))),
                React.createElement(Text, { color: "slate", fontSize: 2, maxWidth: maxWidth, mt: 2 }, description)),
            React.createElement(Flex, { alignItems: "center" }, children)),
        actions));
}
export default SettingsItem;
