import { Folder, Box } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import { getSystemPropTypes } from "../../../../src/utils";
import * as React from 'react';
export default {
  Folder,
  Box,
  PropsTable,
  Example,
  getSystemPropTypes,
  React
};