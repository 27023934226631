import React, { forwardRef } from 'react';
import Loader from '../Loader';
import Position from '../Position';
import { inputStyles, InputElement, LoaderWrapper } from './styles';
import { inputSizes, loaderSizes } from './sizes';
import { inputKinds } from './kinds';
/** @type {any} */
const Input = forwardRef(({ size, kind, isLoading, disabled, display, tag, positionWidth, required, hasTransition = true, ...props }, ref) => {
    const stateProps = (isLoading || disabled) && {
        ...inputKinds.disabled,
        disabled: true,
        pointerEvents: 'none',
    };
    /* Order of props is important here because the last two objects contain
     * stateful props which should overwrite all the rest */
    const allProps = {
        ...inputSizes[size],
        ...inputKinds[kind],
        ...props,
        ...stateProps,
    };
    return (React.createElement(Position, { display: display, position: "relative", width: positionWidth },
        React.createElement(InputElement, Object.assign({ as: tag, hasTransition: hasTransition, ref: ref, required: required }, allProps, { display: display })),
        isLoading && (React.createElement(LoaderWrapper, { height: inputSizes[size].height, width: inputSizes[size].height },
            React.createElement(Loader, Object.assign({}, loaderSizes[size]))))));
});
/** @type {any} */
Input.defaultProps = {
    disabled: false,
    display: 'block',
    fontFamily: 0,
    fontWeight: 400,
    isLoading: false,
    kind: 'default',
    size: 1,
};
export default Input;
export { inputStyles };
