export const sizes = [
    {
        fontSize: 2,
        inputSize: 1,
        textareaSize: 1,
    },
    {
        fontSize: 2,
        inputSize: 1,
        textareaSize: 1,
    },
    {
        fontSize: 3,
        inputSize: 2,
        textareaSize: 2,
    },
    {
        fontSize: 3,
        inputSize: 3,
        textareaSize: 3,
    },
];
