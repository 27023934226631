import styled from 'styled-components';
import { border, color, layout, space } from 'styled-system';
export const AvatarWrapper = styled.div `
  ${border};
  ${color};
  ${layout};
  ${space};
  position: relative;
`;
export const AnimationHolder = styled.div `
  ${color};
  animation-delay: 300ms;
  transition: opacity 300ms ease-in-out;
`;
export const Online = styled.div `
  ${border};
  ${color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  right: 4px;
`;
export const Border = styled.div `
  ${border};

  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
`;
