import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
export default function Li({ children, ...props }) {
    return React.createElement(LiElement, Object.assign({}, props), children);
}
const LiElement = styled.li `
  ${space};
`;
Li.defaultProps = {
    mb: 0,
};
