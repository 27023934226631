import { colors } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
export function getRowBg(isSelected, isLoading, isDisabled) {
    const green005 = hexToRGB(colors.green, 0.05);
    const green008 = hexToRGB(colors.green, 0.08);
    if (isSelected && !isLoading && !isDisabled) {
        return {
            bg: green005,
            hoverBg: green008,
        };
    }
    if (isLoading || isDisabled) {
        return {
            bg: 'snow',
            hoverBg: 'snow',
        };
    }
    return {
        bg: 'white',
        hoverBg: 'snow',
    };
}
