import { Card, Image } from '@marvelapp/ui';
import PropsTable from "../../../../src/components/PropsTable";
import Example from "../../../../src/components/Example";
import { getSystemPropTypes } from "../../../../src/utils";
import thumbnail from "../../../../content/docs/components/assets/thumbnail.png";
import * as React from 'react';
export default {
  Card,
  Image,
  PropsTable,
  Example,
  getSystemPropTypes,
  thumbnail,
  React
};