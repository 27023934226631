import { Component } from 'react';
import PropTypes from 'prop-types';
class EscKeyHandler extends Component {
    constructor() {
        super(...arguments);
        this.escFunction = (event) => {
            const isEscKey = event.key === 'escape' || event.keyCode === 27;
            if (isEscKey && this.props.onEscapeKey) {
                this.props.onEscapeKey();
            }
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }
    render() {
        return null;
    }
}
EscKeyHandler.propTypes = {
    onEscapeKey: PropTypes.func,
};
export default EscKeyHandler;
