import React, { forwardRef } from 'react';
import { Input } from '@marvelapp/ui';
import { sizes } from './sizes';
import Label from './Label';
/** @type {any} */
const InputWithLabel = forwardRef(({ id, isOptional, label, size = 3, fontSize, ...props }, ref) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { fontSize: fontSize, id: id, isOptional: isOptional, size: size }, label),
        React.createElement(Input, Object.assign({ id: id, ref: ref, size: sizes[size].inputSize, width: 1 }, props))));
});
export default InputWithLabel;
